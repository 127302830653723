import React, { useState, useRef } from "react";
import { Button, Row, Col, Modal, Input } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
// import RefundBtn from "../../../assets/images/refund-btn.svg";
// import PrintBillBtn from "../../../assets/images/print-list.svg";
// import PrintReceiptBtn from "../../../assets/images/print-receipt.svg";
// import ParkedBtn from "../../../assets/images/parked-orders.svg";
import HomeGreen from "../../../assets/images/home-green.svg";
import RefundIcon from "../../../assets/images/refund.svg";
import PrintIcon from "../../../assets/images/print.svg";
import TimerIcon from "../../../assets/images/timer.svg";
import ok from "../../../assets/images/tick.svg";
import Backspace from "../../../assets/images/backspac.png";
import data from "../../../constants/retailPos.json";
import AspectRatio from "react-aspect-ratio";
import TextArea from "rc-textarea";

const BillManagement = () => {
  const history = useHistory();
  const [refundModalShow, setRefundModalShow] = useState(false);
  const [refundConfirmModalShow, setRefundConfirmModalShow] = useState(false);
  const [refundRemarksModalShow, setRefundRemarksModalShow] = useState(false);
  const [keypadInput, setKeypadInput] = useState("");
  const [layout, setLayout] = useState("default");
  const keyboard = useRef();
  const [weight, setWeight] = useState("");

  const handleHome = () => {
    history.push("/pos");
  }

  const onChangeWeight = (event) => {
    setWeight(event.target.value);
  };

  const handleWeight = (value) => {
    // if (weight === "" && value === "x") {
    //   setWeight("");
    // } else if (value === "x") {
    //   setWeight(`${weight.toString().substring(0, weight.toString().length - 1)}`);
    // } else {
    //   setWeight(`${weight}${value}`);
    // }
  };

  const addQuantity = () => {
    // const validation = RegExp("^[0-9]+(.[0-9]{1,2})?$");
    // if (validation.test(parseFloat(weight))) {
    //   const netWeight = parseFloat(weight) - parseFloat(selectedProductInCart.weight);
    //   addProduct(selectedProductInCart, netWeight);
    //   setIsQtyUpdate(false);
    // } else {
    //   console.warn("Invalid input", weight);
    // }
  };

  const handleKeyboardInputs = (value) => {
    setKeypadInput(value);
    console.log("Input changed", value);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const handleKeyPress = (button) => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleRemarksValue = (event) => {
    const keyboardInput = event.target.value;
    setKeypadInput(keyboardInput);
    keyboard.current.setKeypadInput(keyboardInput);
  };

  return (
    <div>
      <div className="managementMainDiv">
        <Row>
          <Col span={6}>
            <Button size="large" className="mngBtns" onClick={() => setRefundModalShow(true)}>
              <span className="mngBtnText">Refund</span> <img src={RefundIcon} alt="" className="mngBtnIcon" />
            </Button>
            <Button size="large" className="mngBtns">
              <span className="mngBtnText">Print Last Bill</span> <img src={PrintIcon} alt="" className="mngBtnIcon" />
            </Button>
            <Button size="large" className="mngBtns">
              <span className="mngBtnText">Print By Receipt Number</span> <img src={PrintIcon} alt="" className="mngBtnIcon" />
            </Button>
            <Button size="large" className="mngBtns">
              <span className="mngBtnText">Parked Orders</span> <img src={TimerIcon} alt="" className="mngBtnIcon" />
            </Button>
            {/* <img src={RefundBtn} alt="" className="mngBtns"/>
			<img src={PrintBillBtn} alt="" className="mngBtns"/>
			<img src={PrintReceiptBtn} alt="" className="mngBtns"/>
			<img src={ParkedBtn} alt="" className="mngBtns"/> */}
            <div style={{ marginTop: "16rem" }}>
              <img src={HomeGreen} alt="" width="25%" onClick={handleHome}/>
            </div>
          </Col>
          <Col span={18} />
        </Row>
      </div>

      {/* Receipts Search Number Modal Starts */}
      <Modal visible={refundModalShow} closable={false} footer={null} width="30%" bodyStyle={{ padding: "15px" }} style={{ top: "40px" }}>
        <Row>
          <Col span={16}>
            <p className="enter-receipt">Enter Receipt Number</p>
          </Col>
          <Col span={8} style={{ alignSelf: "center" }}>
            <CloseOutlined style={{ float: "right", fontSize: "16px", marginTop: "-4px" }} onClick={() => setRefundModalShow(false)} />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input
              size="large"
              placeholder="Search"
              value={weight}
              onChange={onChangeWeight}
              allowClear={false}
              style={data.posScreenStyles.quantityUpdateCard.quantityUpdateInput}
              prefix={<SearchOutlined style={data.posScreenStyles.customerModal.searchIcon} />}
            />
          </Col>
          <Col span={24}>
            <div>
              <p className="search-results">Search Results</p>
              <p className="receipt-num" onClick={() => setRefundConfirmModalShow(true)}>
                83292301
              </p>
            </div>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("1")}>
                1
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("2")}>
                2
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("3")}>
                3
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button
                style={data.posScreenStyles.quantityUpdateCard.quantityBtn}
                // onClick={() => setIsQtyUpdate(false)}
              >
                <CloseOutlined style={{ fontSize: "24px" }} />
                {/* <img src={Aclose} style={data.posScreenStyles.quantityUpdateCard.qtyBtnClose} alt="close icon" /> */}
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("4")}>
                4
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("5")}>
                5
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("6")}>
                6
              </button>
            </AspectRatio>
          </Col>
          <Col span={6}>
            <AspectRatio ratio="1">
              <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("x")}>
                <img src={Backspace} alt="back space" style={data.posScreenStyles.quantityUpdateCard.qtyBtnBack} />
              </button>
            </AspectRatio>
          </Col>
          <Col span={18}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <AspectRatio ratio="1">
                  <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("7")}>
                    7
                  </button>
                </AspectRatio>
              </Col>
              <Col span={8}>
                <AspectRatio ratio="1">
                  <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("8")}>
                    8
                  </button>
                </AspectRatio>
              </Col>
              <Col span={8}>
                <AspectRatio ratio="1">
                  <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("9")}>
                    9
                  </button>
                </AspectRatio>
              </Col>
              <Col span={16}>
                <button className="zeroBtn" style={data.posScreenStyles.quantityUpdateCard.quantityBtnZero} onClick={() => handleWeight("0")}>
                  0
                </button>
              </Col>
              <Col span={8}>
                <AspectRatio ratio="1">
                  <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight(".")}>
                    00
                  </button>
                </AspectRatio>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row gutter={[16, 16]} style={{ height: "100%" }}>
              <Col span={24}>
                <button style={data.posScreenStyles.quantityUpdateCard.quantityBtnOk} onClick={() => addQuantity()}>
                  {/* <AspectRatio ratio="1"> */}
                  <img src={ok} alt="ok" width={40} />
                  {/* </AspectRatio> */}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      {/* Receipts Search Number Modal Ends */}

      {/* Refund Confirmation Modal Starts */}
      <Modal visible={refundConfirmModalShow} closable={false} footer={null} width="30%" style={{ top: "200px" }}>
        <Row>
          <Col span={24} style={{ alignSelf: "center" }}>
            <CloseOutlined style={{ float: "right" }} onClick={() => setRefundConfirmModalShow(false)} />
          </Col>
        </Row>
        <div>
          <Row>
            <Col span={24}>
              <p className="refundModalTitle">Are you sure want a Refund?</p>
            </Col>
          </Row>
          <Row className="refundBtns">
            <Button size="medium" className="refundYesBtn" onClick={() => setRefundRemarksModalShow(true)}>
              Yes
            </Button>
            <Button size="medium" className="refundNoBtn" onClick={() => setRefundConfirmModalShow(false)}>
              No
            </Button>
          </Row>
        </div>
      </Modal>
      {/* Refund Confirmation Modal Ends */}

      {/* Refund Remarks Modal Starts */}
      <Modal visible={refundRemarksModalShow} closable={false} footer={null} bodyStyle={{ padding: "15px" }}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={16}>
            <p className="enter-receipt">Enter Receipt Number</p>
          </Col>
          <Col span={8} style={{ alignSelf: "center" }}>
            <CloseOutlined style={{ float: "right", fontSize: "16px", marginTop: "-4px" }} onClick={() => setRefundRemarksModalShow(false)} />
          </Col>
        </Row>
        <p className="receipt-num" onClick={() => setRefundConfirmModalShow(true)}>
          Reason for refund
        </p>
        <TextArea rows={3} value={keypadInput} onChange={handleRemarksValue} placeholder="Type Here" className="refund-remarks" />
        <Keyboard keyboardRef={(r) => (keyboard.current = r)} layoutName={layout} onChange={handleKeyboardInputs} onKeyPress={handleKeyPress} />
      </Modal>
      {/* Refund Remarks Modal Ends */}
    </div>
  );
};

export default BillManagement;
