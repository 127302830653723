import { useEffect } from "react";
let dataString = "";
let timeoutId;

const BarCodeComponent = (props) => {
  const { onScan } = props;
  const handleKeyPress = (e) => {
    const { charCode, key } = e;
    if (timeoutId === undefined && dataString !== "") {
      timeoutId = setTimeout(() => {
        dataString = "";
        timeoutId = undefined;
      }, 5000);
    }
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode === 45) {
      dataString += key;
    }
    if (dataString !== "" && charCode === 13) {
      onScan(dataString);
      dataString = "";
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      dataString = "";
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []); // eslint-disable-line

  return null;
};

export default BarCodeComponent;
