import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Form, Input, Button, Modal, message, Select, Empty, notification, Spin } from "antd";
import { MinusOutlined, PlusOutlined, LoadingOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { serverUrl, CWCoreServicesUrl, fileDownloadUrl } from "../../constants/config";
import Axios from "axios";
import _ from "lodash";
import { clock } from "../../utility/clock";
// import logoIcon from "../../assets/images/cwpos-blue.png";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import DefaultProductImage from "../../assets/images/default-product.webp";
import CircleCloseIcon from "../../assets/images/circle-close-icon.svg";
import Backspace from "../../assets/images/backspac.png";
import Tick from "../../assets/images/tick-small.svg";
import "../style.css";
import db from "../../database";
import data from "../../constants/purchaseGrn.json";
import BarCodeComponent from "../../lib/BarCodeComponet";
import AspectRatio from "react-aspect-ratio";

const { Option } = Select;
const { TextArea } = Input;

const PurchaseGRN = () => {
  const dynamicHeight = 0.5625;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const history = useHistory();
  const [loading, setLoading] = useState({ status: false, message: "... Loading" });

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  const handlePosLogo = () => {
    history.push("/dashboard");
  };

  const [confirmModalClose, setConfirmModalClose] = useState(false);

  const handleHome = (e) => {
    if (e.keyCode === 27 && e.keyCode !== 13) {
      if (document.activeElement.className === "ant-modal-wrap") {
        setConfirmModalClose(false);
      } else {
        Modal.confirm({
          content: <div>Go to Home Screen?</div>,
          okText: "Ok",
          onOk: () => {
            history.push("/dashboard");
            setConfirmModalClose(false);
          },
          onCancel: () => {
            setConfirmModalClose(false);
          },
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleHome);
    return () => {
      window.removeEventListener("keydown", handleHome);
    };
  }, []);

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [supplierList, setSupplierList] = useState([]);
  const [filteredData, setFilteredData] = useState({ supplier: {}, skuInput: "", remarks: "" });
  const [posGrnConfig, setPosGrnConfig] = useState({});

  useEffect(() => {
    const supplierInput = {
      query: `query {      comboFill(tableName:"p_supplier", pkName:"p_supplier_id", identifier:"value, name, description, referenceno, p_supplier_category_id,(select name from p_supplier_category where p_supplier_category_id = p_supplier.p_supplier_category_id) as suppliercategory, gstno, p_pricelist_id,(select name from p_pricelist where p_pricelist_id = p_supplier.p_pricelist_id) as Pricelistname, (select istaxincluded from p_pricelist where p_pricelist_id = p_supplier.p_pricelist_id) as istaxincluded,islocalpurchase, p_supplierpaymentterms, taxid", whereClause:"p_supplier_id in (select p_supplier_id from p_supplier_bunit where p_bunit_id = '${tillData.tillAccess.csBunit.csBunitId}')")       }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: supplierInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { comboFill } = response.data.data;
      let newComboFill = JSON.parse(comboFill);
      setSupplierList([...newComboFill]);
    });
    Axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `query {
          getPOSConfig(application: "GRN") {
            cwrPosConfigId
            application
            configJson
          }
        }`,
      },
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const grnConfig = JSON.parse(response.data.data.getPOSConfig[0].configJson);
      setPosGrnConfig({ ...grnConfig });
    });
  }, []);

  const supplierListSelect = supplierList.map((suppList) =>
    suppList.bunit !== null && suppList.islocalpurchase === "Y" ? (
      <Option key={suppList.recordid} value={suppList.recordid}>
        {suppList.name || ""}
      </Option>
    ) : (
      ""
    )
  );

  const [supplierProductList, setSupplierProductList] = useState([]);
  const [supplierAddress, setSupplierAddress] = useState([]);
  const [selectedRow, setSelectedRow] = useState("");

  // useEffect(() => {
  //   if(window.localStorage.getItem('filterData')) {
  //     let filterobj = JSON.parse(window.localStorage.getItem('filterData'))
  //     setFilteredData(filterobj?.filteredData || {});
  //     setSupplierProductList(filterobj?.filterList || []);
  //   }
  // }, []);

  // useEffect(() => {
  //   window.localStorage.setItem('filterData', JSON.stringify({
  //     filteredData: filteredData,
  //     filterList: supplierProductList
  //   }));
  // }, [filteredData, supplierProductList]);

  const productListCardRef = useRef(null);

  const onSelect = (value) => {
    setFilteredData({ ...filteredData, supplier: { recordid: value } });
    const supplierProductsInput = {
      query: `query {     getSupplierProduct(supplierId : "${value}"     bUnitId : "${tillData.tillAccess.csBunit.csBunitId}"     sku : null     upc : ${null}) {         
        supplierProductId         
        clientId         
        clientName         
        bUnitId         
        bUnitName         
        supplierId         
        supplierName         
        productId         
        productName         
        productCode         
        qtyOnHand         
        uomId         
        uomName         
        productCategoryId         
        productcategoryName         
        taxCategoryId         
        taxCategoryName         
        taxId         
        taxName         
        taxRate         
        priceStd  
        actualCostPrice       
        priceList         
        twoWeekSale         
        fourWeekSale         
        upc         
        description         
        margin         
        supp_pricelist_id         
        supp_price_name         
        isdefault         
        istaxincluded   
        batchedProduct
        shelfLife
        salePrice      
        alternateUomList              
        { 
            alternateUomId 
            uomId 
            uomName               
            auomupc 
            conversionrate 
            trxTypeId 
            openOrderQty 
        }         
        sup_minqty         
        sup_uom_id         
        sup_uom_name     
    } 
}`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: supplierProductsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      let newSupplierProduct = [];
      let { getSupplierProduct } = response.data.data;
      let prodCode = _.map([...getSupplierProduct] || [], "productCode");
      db.products
        .where("value")
        .startsWithAnyOfIgnoreCase(`${prodCode}`)
        .toArray()
        .then((productsFetched) => {
          if (productsFetched?.length > 0) {
            newSupplierProduct = [...getSupplierProduct].map((itemLine) => {
              let itemImageIndex = _.findIndex(productsFetched, (item) => item.value === itemLine.productCode);
              return itemImageIndex >= 0
                ? {
                    ...itemLine,
                    img: productsFetched[itemImageIndex]?.imageurl,
                    qty: 0,
                    lineTotal: 0,
                    priceStd: Number(itemLine.priceStd).toFixed(2),
                    actualCostPrice: Number(itemLine.actualCostPrice).toFixed(2),
                  }
                : {
                    ...itemLine,
                    qty: 0,
                    lineTotal: 0,
                    priceStd: Number(itemLine.priceStd).toFixed(2),
                    actualCostPrice: Number(itemLine.actualCostPrice).toFixed(2),
                  };
            });
          } else {
            newSupplierProduct = [...getSupplierProduct].map((prodItem) => ({
              ...prodItem,
              qty: 0,
              lineTotal: 0,
              priceStd: Number(prodItem.priceStd).toFixed(2),
              actualCostPrice: Number(prodItem.actualCostPrice).toFixed(2),
            }));
          }
          setSupplierProductList([...newSupplierProduct]);
          if (newSupplierProduct.length > 0) {
            setSelectedRow(newSupplierProduct[0].productId);
          }
          productListCardRef.current.focus();
          const comboFillInput = {
            query: `query {      comboFill(tableName:"p_supplier_address", pkName:"p_supplier_address_id", identifier:"fulladdress", whereClause:"p_supplier_id='${value}'")       }`,
          };
          Axios({
            url: CWCoreServicesUrl,
            method: "POST",
            data: comboFillInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          }).then((response) => {
            const { comboFill } = response.data.data;
            let newSupplierAddress = JSON.parse(comboFill);
            setSupplierAddress([...newSupplierAddress]);
          });
        });
    });
  };

  const onFieldButton = (fieldIndex, action) => {
    let newSupplierProductList = [...supplierProductListRef.current];
    if (action === "plus") {
      newSupplierProductList[fieldIndex].qty += 1;
    } else if (action === "minus" && newSupplierProductList[fieldIndex].qty > 0) {
      newSupplierProductList[fieldIndex].qty -= 1;
    } else if (action !== "plus" && action !== "minus") {
      newSupplierProductList[fieldIndex].qty = Number(action);
    }
    newSupplierProductList[fieldIndex].lineTotal =
      newSupplierProductList[fieldIndex].qty *
      (posGrnConfig.basePriceCalc === "Purchase Price" ? newSupplierProductList[fieldIndex].priceStd : newSupplierProductList[fieldIndex].actualCostPrice);
    setSupplierProductList([...newSupplierProductList]);
  };

  const handleSku = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      const skuVal = event.target.value;
      const index = supplierProductList.findIndex((element) => {
        return element.productCode === skuVal || element.productName === skuVal || element.upc === skuVal;
      });
      if (index !== -1) {
        supplierProductList[index].qty += 1;
        supplierProductList[index].lineTotal =
          supplierProductList[index].qty * (posGrnConfig.basePriceCalc === "Purchase Price" ? supplierProductList[index].priceStd : supplierProductList[index].actualCostPrice);
        const presentProduct = supplierProductList[index];
        supplierProductList.splice(index, 1);
        supplierProductList.unshift(presentProduct);
        setSupplierProductList([...supplierProductList]);
      } else {
        message.error("Product not found");
      }
    }
  };

  const handlePrices = (prodIndex, value, fieldName) => {
    let newSupplierProductList = [...supplierProductList];
    if (fieldName === "priceField") {
      posGrnConfig.basePriceCalc === "Purchase Price" ? supplierProductList[prodIndex].priceStd : (supplierProductList[prodIndex].actualCostPrice = value);
      supplierProductList[prodIndex].lineTotal =
        supplierProductList[prodIndex].qty *
        (posGrnConfig.basePriceCalc === "Purchase Price" ? supplierProductList[prodIndex].priceStd : supplierProductList[prodIndex].actualCostPrice);
    } else if (fieldName === "mrpField") {
      supplierProductList[prodIndex].priceList = value;
    }
    setSupplierProductList([...newSupplierProductList]);
  };

  const key = `open${Date.now()}`;
  const btn = (
    <Button className="notificationCnfmBtn" onClick={() => notification.close(key)}>
      Confirm
    </Button>
  );

  // Add Products Modal Starts //

  const [addProdToStockDisplayModal, setAddProdToStockDisplayModal] = useState(false);
  const [addProdsToStockList, setAddProdsToStockList] = useState([]);
  const [addProdToStock, setAddProdToStock] = useState("");
  const [searchAddProdToStock, setSearchAddProdToStock] = useState(addProdsToStockList);
  const [displayManualQtyWeightInput, setDisplayManualQtyWeightInput] = useState(false);
  const [productWeightModalInput, setProductWeightModalInput] = useState("");
  const [currentWeightSelectedProduct, setCurrentWeightSelectedProduct] = useState({});

  const handleAddProdToStock = () => {
    setAddProdToStock("");
    db.products
      .where("mProductId")
      .startsWithAnyOfIgnoreCase("")
      .toArray()
      .then((productsFetched) => {
        let newProductsFetched = productsFetched.map((item, index) => {
          return {
            ...item,
            qty: 0,
          };
        });
        setAddProdsToStockList([...newProductsFetched]);
        setSearchAddProdToStock([...newProductsFetched]);
        setAddProdToStockDisplayModal(true);
      });
  };

  const addProdToStockSearch = (value) => {
    if (value !== "") {
      const results = addProdsToStockList.filter((prodToStock) => {
        return prodToStock.name.toLowerCase().startsWith(value.toLowerCase());
      });
      setSearchAddProdToStock(results);
    } else {
      setSearchAddProdToStock(addProdsToStockList);
    }
    setAddProdToStock(value);
  };

  const handleWeightModal = (prod) => {
    setCurrentWeightSelectedProduct(prod);
    setAddProdToStockDisplayModal(false);
    if (prod.isDecimal === true) {
      setDisplayManualQtyWeightInput(true);
    } else {
      addManualWeightToProduct(prod, 1);
    }
  };

  const handleWeightManual = (value) => {
    if (productWeightModalInput === "" && value === "x") {
      setProductWeightModalInput("");
    } else if (value === "x") {
      setProductWeightModalInput(`${productWeightModalInput.toString().substring(0, productWeightModalInput.toString().length - 1)}`);
    } else {
      setProductWeightModalInput(`${productWeightModalInput}${value}`);
    }
  };

  const addManualWeightToProduct = (value, qty) => {
    let newSupplierProductList = [...supplierProductList];
    const prodIndex = _.findIndex(addProdsToStockList, ["mProductId", value.mProductId]);
    addProdsToStockList[prodIndex].productId = value.mProductId;
    addProdsToStockList[prodIndex].uomId = value.csUomId;
    addProdsToStockList[prodIndex].stockUomId = value.csUomId;
    addProdsToStockList[prodIndex].qty = Number(qty);
    addProdsToStockList[prodIndex]["remarks"] = filteredData.remarks;
    addProdsToStockList[prodIndex].productName = value.name;
    addProdsToStockList[prodIndex].productCode = value.value;
    addProdsToStockList[prodIndex].priceStd = 0;
    addProdsToStockList[prodIndex].actualCostPrice = 0;
    addProdsToStockList[prodIndex].priceList = addProdsToStockList[prodIndex].slistprice;
    addProdsToStockList[prodIndex].lineTotal =
      addProdsToStockList[prodIndex].qty *
      (posGrnConfig.basePriceCalc === "Purchase Price" ? addProdsToStockList[prodIndex].priceStd : addProdsToStockList[prodIndex].actualCostPrice);
    const productIndex = _.findIndex(newSupplierProductList, ["mProductId", value.mProductId]);
    if (productIndex !== -1) {
      newSupplierProductList.splice(productIndex, 1, addProdsToStockList[prodIndex]);
    } else {
      newSupplierProductList.push(addProdsToStockList[prodIndex]);
      newSupplierProductList.reverse();
    }
    setSupplierProductList([...newSupplierProductList]);
    setDisplayManualQtyWeightInput(false);
    setProductWeightModalInput("");
    setFilteredData({
      ...filteredData,
      remarks: "",
    });
    productListCardRef.current.focus();
  };

  // Add Products Modal Ends //

  const handleReceive = () => {
    setLoading({ status: true, message: "... Loading" });
    const formatYmd = (date) => date.toISOString().slice(0, 10);
    const newDate = formatYmd(new Date());
    let supplierId = filterDataRef.current.supplier.recordid;
    let supplierAddressId = supplierAddressRef.current[0].recordid;
    const productArray = [];
    supplierProductListRef.current.map((item, index) => {
      item.qty > 0 &&
        productArray.push(
          `{
            productId : "${item.productId}"
            uomId : "${item.uomId}"
            qty : ${item.qty}
            orderLineId : null
            remark : null
            description : null
            stockUomId : "${item.uomId}"
            freeQty : 0
            unitprice : ${posGrnConfig.basePriceCalc === "Purchase Price" ? item.priceStd : item.actualCostPrice}
            listprice : ${item.priceList}
            discountvalue : 0
            discountType: null
            totalDiscount: 0
            batchId:null
            batchNo:null
            expiryDate:null
            storagebinId:null
          }`
        );
      return null;
    });

    const createGRNInput = {
      query: `mutation {
        createGRN(GRN: {
          bunitId: "${tillData.tillAccess.csBunit.csBunitId}", 
          supplierId: "${supplierId}", 
          supplierAddressId: "${supplierAddressId}", 
          isReturn: "N", 
          orderId: null, 
          remark: "", 
          supplierRef: null, 
          movementDate: "${newDate}", 
          taskId: null, 
          invoiceDate: "${newDate}", 
          receiptLines: [${productArray}]
          landedCost:[]    
      }) {
          type
          code
          message
          documentNo
          extraParam
        }
      }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: createGRNInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      if (response.data.data.createGRN.code === "200") {
        setLoading({ status: false, message: "... Loading" });
        let receiptId = response.data.data.createGRN.extraParam;
        let newReceiptId = JSON.parse(receiptId);
        Modal.confirm({
          title: `${response.data.data.createGRN.message}`,
          content: <div>Do you want print pdf?</div>,
          okText: "Ok",
          onOk: () => {
            handlePrintPdf(newReceiptId.receiptId);
            setConfirmPdfModal(false);
          },
          onCancel: () => {
            setConfirmPdfModal(false);
            notification.open({
              message: response.data.data.createGRN.message,
              type: "success",
              btn,
              key,
              duration: 15,
              style: {
                width: 500,
              },
            });
          },
        });
        setFilteredData({
          ...filteredData,
          supplier: {},
          remarks: "",
          skuInput: "",
        });
        setSupplierProductList([]);
      } else {
        message.error("Error Creating Goods Receipt");
      }
    });
  };

  const [confirmPdfModal, setConfirmPdfModal] = useState(false);

  const handlePrintPdf = (value) => {
    const pdfInput = {
      query: `query {reportTemplate(ad_tab_id:"0953FB1201D94CA39671ADF5D4CB967B",recordId:"${value}")}`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: pdfInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      console.log("response");
      if (response.data.data.reportTemplate !== null) {
        getPrintCommand(response.data.data.reportTemplate);
      }
    });
  };

  const getPrintCommand = (fileName) => {
    Axios({
      url: `${fileDownloadUrl}`.concat(`${fileName}`),
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.setAttribute("id", "downloadlink");
      link.href = fileURL;
      link.setAttribute("download", `${fileName}`);
      link.click();
    });
  };

  const supplierSelectRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      supplierSelectRef.current.focus();
    }, 100);
  }, []);

  const searchProductsRef = useRef();
  useEffect(() => {
    if (addProdToStockDisplayModal) {
      setTimeout(() => {
        searchProductsRef.current.focus();
      }, 100);
    }
  }, [addProdToStockDisplayModal]);

  const productWeightInputRef = useRef();
  useEffect(() => {
    if (displayManualQtyWeightInput) {
      setTimeout(() => {
        productWeightInputRef.current.focus();
      }, 100);
    }
  }, [displayManualQtyWeightInput]);

  const skuInputRef = useRef();

  const handleKey = (e) => {
    const { altKey, keyCode } = e;
    if (altKey) {
      if (keyCode === 80) {
        handleAddProdToStock();
      }
      if (keyCode === 67) {
        setTimeout(() => {
          supplierSelectRef.current.focus();
        }, 100);
      }
      if (keyCode === 83) {
        setTimeout(() => {
          skuInputRef.current.focus();
        }, 100);
      }
      if (keyCode === 82) {
        handleReceive();
      }
    }

    if (keyCode === 38) {
      if(supplierProductListRef.current.length > 0){
        handleUp();
      }
    }

    if (keyCode === 40) {
      if(supplierProductListRef.current.length > 0){
      handleDown();
      }
    }

    if (keyCode === 187 || keyCode === 107) {
      const prodIndex = supplierProductListRef.current.findIndex((item) => item.productId === selectedRowRef.current);
      console.log('prodIndex', prodIndex);
      onFieldButton(prodIndex, "plus");
    }
    if (keyCode === 109 || keyCode === 189) {
      const prodIndex = supplierProductListRef.current.findIndex((item) => item.productId === selectedRowRef.current);
        onFieldButton(prodIndex, "minus");
    }
  };

  const filterDataRef = useRef();
  useEffect(() => {
    filterDataRef.current = filteredData;
  }, [filteredData]);

  const supplierProductListRef = useRef();
  useEffect(() => {
    supplierProductListRef.current = supplierProductList;
  }, [supplierProductList]);

  const supplierAddressRef = useRef();
  useEffect(() => {
    supplierAddressRef.current = supplierAddress;
  }, [supplierAddress]);

  const selectedRowRef = useRef();
  useEffect(() => {
    selectedRowRef.current = selectedRow;
  }, [selectedRow]);

  const handleUp = () => {
    const prodIndex = supplierProductListRef.current.findIndex((item) => item.productId === selectedRowRef.current);
    console.log("prodIndex", prodIndex);
    if (prodIndex !== -1 && prodIndex !== 0) {
      setSelectedRow(supplierProductListRef.current[prodIndex - 1].productId);
    }
    productListCardRef.current.focus();
  };

  const handleDown = () => {
    const prodIndex = supplierProductListRef.current.findIndex((item) => item.productId === selectedRowRef.current);
    console.log("prodIndex", prodIndex, prodIndex !== supplierProductListRef.current.length - 1);
    if (prodIndex !== -1 && prodIndex !== supplierProductListRef.current.length - 1) {
      setSelectedRow(supplierProductListRef.current[prodIndex + 1].productId);
    }
    productListCardRef.current.focus();
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  const antIcon = (
    <LoadingOutlined
      spin
      style={{
        position: "absolute",
        top: "30vh",
      }}
      size="large"
    />
  );

  return (
    <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
      <div>
        <BarCodeComponent onScan={handleSku} />
        <div style={data.purchaseGrn.header}>
          <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
            <Col span={2} style={{ alignSelf: "center" }}>
              <img src={MenuIcon} width="27%" alt="" />
            </Col>
            <Col span={10} style={{ alignSelf: "center" }}>
              <label style={data.purchaseGrn.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
            </Col>
            <Col span={8} style={{ alignSelf: "center" }}>
              <label style={data.purchaseGrn.header.tillName}>{tillData.tillAccess.cwrTill.till}</label>
            </Col>
            <Col span={2} style={{ alignSelf: "center", textAlign: "right" }}>
              <label style={data.purchaseGrn.header.time}>{displayClock}</label>
            </Col>
            <Col span={2} style={{ alignSelf: "center", textAlign: "right" }}>
              <img onClick={handlePosLogo} src={POSLogo} style={data.purchaseGrn.header.logoIcon} alt="" />
            </Col>
          </Row>
        </div>
        <div style={{ padding: 10 }}>
          <p style={data.purchaseGrn.purchaseText}>Purchase</p>
          <Row style={{ paddingBottom: 10 }}>
            <Col span={16}>
              <Row>
                <Col span={9}>
                  <Row>
                    <Col span={5} style={{ alignSelf: "center" }}>
                      <p style={data.purchaseGrn.inputLabel}>Supplier</p>
                    </Col>
                    <Col span={19}>
                      <Select
                        ref={supplierSelectRef}
                        // suffixIcon={<CaretDownOutlined />}
                        placeholder="Select"
                        onChange={(e) => onSelect(e)}
                        value={filteredData.supplier.name}
                        style={data.purchaseGrn.supplierSelect}
                        showSearch
                        // allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        className="supplierSelect"
                      >
                        {supplierListSelect}
                      </Select>
                    </Col>
                  </Row>
                </Col>
                <Col span={9} style={{ paddingLeft: 10 }}>
                  <Row>
                    <Col span={7} style={{ alignSelf: "center" }}>
                      <p style={data.purchaseGrn.inputLabel}>SKU/Name</p>
                    </Col>
                    <Col span={17}>
                      <Input
                        ref={skuInputRef}
                        style={data.purchaseGrn.skuInput}
                        className="skuInput"
                        allowClear
                        value={filteredData.skuInput}
                        onChange={(e) => setFilteredData({ ...filteredData, skuInput: e.target.value })}
                        onKeyPress={handleSku}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={6} style={{ paddingLeft: 10, alignSelf: "center" }}>
                  <p
                    style={{
                      marginBottom: 0,
                      fontSize: "13px",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      color: Object.keys(filteredData.supplier).length > 0 ? "#0C4DA9" : "#dddddd",
                      opacity: 1,
                      letterSpacing: "0.26px",
                      cursor: "pointer",
                    }}
                    onClick={() => (Object.keys(filteredData.supplier).length > 0 ? handleAddProdToStock() : "")}
                  >
                    + ADD PRODUCTS
                  </p>
                </Col>
              </Row>
            </Col>
            <Col span={8} />
          </Row>
          <Card key="z" style={data.purchaseGrn.mainCardFirst} bodyStyle={{ padding: 0 }}>
            <Row style={data.purchaseGrn.headingRow}>
              <Col span={7}>
                <strong>Product</strong>
              </Col>
              <Col span={5} style={data.purchaseGrn.colAlign}>
                <strong>Quantity</strong>
              </Col>
              <Col span={4} style={data.purchaseGrn.colAlign}>
                <strong>Price</strong>
              </Col>
              <Col span={4} style={data.purchaseGrn.colAlign}>
                <strong>MRP</strong>
              </Col>
              <Col span={4} style={data.purchaseGrn.colAlign}>
                <strong>Line Total</strong>
              </Col>
            </Row>
            {supplierProductList.length > 0 ? (
              <div>
                <Scrollbars autoHide className="purchaseGrnScroll">
                  {supplierProductList.map((item, index) => (
                    <Row
                      style={{ padding: "10px 10px 0px" }}
                      ref={selectedRow === item.productId ? productListCardRef : null}
                      tabIndex={0}
                    >
                      <Col span={7}>
                        <Row>
                          <Col span={5}>
                            <img onError={setDefaultImage} src={item.img} alt="" style={data.purchaseGrn.prodImgInTable} />
                          </Col>
                          <Col span={19} style={{ paddingTop: 5 }}>
                            <Row>
                              <p style={data.purchaseGrn.desc}>{item.productCode}</p>
                            </Row>
                            <Row>
                              <p style={data.purchaseGrn.prodTitle}>{item.productName}</p>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={5} style={{ textAlign: "center", alignSelf: "center" }}>
                        <div style={data.purchaseGrn.qtyInputsDiv}>
                          <MinusOutlined style={data.purchaseGrn.decrementInpt} type="minus" onClick={() => onFieldButton(index, "minus")} />
                          <Form.Item style={data.purchaseGrn.qtyInputFormitem}>
                            <Input
                              type="number"
                              min="0"
                              value={item.qty}
                              style={data.purchaseGrn.qtyInput}
                              onChange={(e) => {
                                onFieldButton(index, e.target.value);
                              }}
                            />
                          </Form.Item>
                          <PlusOutlined style={data.purchaseGrn.incrementInpt} type="plus" onClick={() => onFieldButton(index, "plus")} />
                        </div>
                      </Col>
                      <Col span={4} style={{ textAlign: "center", alignSelf: "center" }}>
                        <Input
                          style={{
                            width: "50%",
                            padding: "5px 10px",
                            backgroundColor: "#ffffff",
                            fontWeight: "normal",
                            textAlign: "center",
                            border: "1px solid #d3cfcf",
                            borderRadius: "5px",
                            outline: "none",
                            boxShadow: "none",
                          }}
                          type="number"
                          readOnly={posGrnConfig.priceEdit === "N" ? true : false}
                          value={posGrnConfig.basePriceCalc === "Purchase Price" ? item.priceStd : item.actualCostPrice}
                          onChange={(e) => handlePrices(index, e.target.value, "priceField")}
                        />
                      </Col>
                      <Col span={4} style={{ textAlign: "center", alignSelf: "center" }}>
                        <Input
                          style={{
                            width: "50%",
                            padding: "5px 10px",
                            backgroundColor: "#ffffff",
                            fontWeight: "normal",
                            textAlign: "center",
                            border: "1px solid #d3cfcf",
                            borderRadius: "5px",
                            outline: "none",
                            boxShadow: "none",
                          }}
                          type="number"
                          readOnly={posGrnConfig.MRPEdit === "N" ? true : false}
                          value={item.priceList}
                          onChange={(e) => handlePrices(index, e.target.value, "mrpField")}
                        />
                      </Col>
                      <Col span={4} style={{ textAlign: "center", alignSelf: "center" }}>
                        <Input readOnly={true} style={data.purchaseGrn.priceTotalAmount} value={Number(item.lineTotal).toFixed(2)} />
                      </Col>
                    </Row>
                  ))}
                </Scrollbars>
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: "10% 0px" }} />
            )}
            <div style={{ display: supplierProductList.length > 0 ? "block" : "none" }}>
              <Row style={{ padding: 10 }}>
                <Col span={7}>
                  <p style={data.purchaseGrn.totalProducts}>Total Products: {_.filter(supplierProductList, (item) => item.qty > 0)?.length || 0}</p>
                </Col>
                <Col span={5}>
                  <p style={data.purchaseGrn.totalQty}>Total Qty: {_.sumBy(supplierProductList, "qty")} </p>
                </Col>
                <Col span={8} />
                <Col span={4}>
                  <p style={data.purchaseGrn.totalValue}>Total: {_.sumBy(supplierProductList, "lineTotal").toFixed(2)}</p>
                </Col>
              </Row>
              <Row style={{ padding: 10 }}>
                <Col span={21} />
                <Col span={3} style={{ float: "right" }}>
                  <Button style={data.purchaseGrn.receiveBtn} onClick={handleReceive}>
                    <span style={data.purchaseGrn.receiveBtn.label}>RECEIVE</span>
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
        {/* ADD PRODUCTS MODAL START */}
        <Modal
          visible={addProdToStockDisplayModal}
          onCancel={() => setAddProdToStockDisplayModal(false)}
          closable={false}
          footer={null}
          // width="40%"
          bodyStyle={{ padding: 15 }}
        >
          <Row>
            <Col span={12}>
              <p style={data.purchaseGrn.addProdToStockModal.modalTitle}>Add Product</p>
            </Col>
            <Col span={12}>
              <img src={CircleCloseIcon} alt="" style={{ float: "right" }} onClick={() => setAddProdToStockDisplayModal(false)} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Input
                ref={searchProductsRef}
                size="large"
                placeholder="Search"
                value={addProdToStock}
                onChange={(e) => {
                  addProdToStockSearch(e.target.value);
                }}
                allowClear
                suffix={<SearchOutlined />}
                className="stockProdSearchIpt"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Scrollbars autoHide className="addWastageProdScroll">
              {searchAddProdToStock && searchAddProdToStock.length > 0
                ? searchAddProdToStock.map((item, index) => (
                    <Row
                      tabIndex={0}
                      onKeyDown={(e) => (e.keyCode === 13 ? handleWeightModal(item) : null)}
                      style={{ marginBottom: 5, cursor: "pointer" }}
                      onClick={() => handleWeightModal(item)}
                    >
                      <Col span={4}>
                        <img onError={setDefaultImage} src={item.imageurl} alt="" style={data.purchaseGrn.prodImgInTable} />
                      </Col>
                      <Col span={20} style={{ paddingTop: 5 }}>
                        <Row>
                          <p style={data.purchaseGrn.desc}>{item.value}</p>
                        </Row>
                        <Row>
                          <p style={data.purchaseGrn.prodTitle}>{item.name}</p>
                        </Row>
                      </Col>
                    </Row>
                  ))
                : ""}
            </Scrollbars>
          </Row>
        </Modal>
        {/* ADD PRODUCTS MODAL END */}

        {/* PRODUCT WEIGHT MODAL START */}
        <Modal
          visible={displayManualQtyWeightInput}
          closable={false}
          onCancel={() => {
            setDisplayManualQtyWeightInput(false);
            setProductWeightModalInput("");
            setFilteredData({ ...filteredData, remarks: "" });
          }}
          footer={null}
          width="45%"
          bodyStyle={{ padding: 10 }}
        >
          <Row>
            <Col span={12}>
              <p style={data.purchaseGrn.productWeightModal.modalTitle}>Enter the product quantity</p>
            </Col>
            <Col span={12}>
              <p style={data.purchaseGrn.productWeightModal.closeIconP}>
                <CloseOutlined
                  onClick={() => {
                    setDisplayManualQtyWeightInput(false);
                    setProductWeightModalInput("");
                    setFilteredData({ ...filteredData, remarks: "" });
                  }}
                  style={data.purchaseGrn.productWeightModal.closeIcon}
                />
              </p>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col span={9}>
              <Card bodyStyle={{ padding: 10 }} style={data.purchaseGrn.productWeightModal.imgCard}>
                <img onError={setDefaultImage} width="100%" height="100%" src={currentWeightSelectedProduct.imageurl} alt="" />
              </Card>
              <Row style={{ paddingTop: 10 }}>
                <h3 style={data.purchaseGrn.productWeightModal.prodHead}>{currentWeightSelectedProduct.name || ""}</h3>
              </Row>
              <Row>
                <Col span={9}>
                  <h3 style={data.purchaseGrn.productWeightModal.prodHead}>MRP</h3>
                  <h3 style={data.purchaseGrn.productWeightModal.prodHead}>Quantity</h3>
                </Col>
                <Col span={15}>
                  <p style={data.purchaseGrn.productWeightModal.prodPriceValue}>Rs. {(currentWeightSelectedProduct.slistprice || 0).toFixed(2)}</p>
                  <p style={data.purchaseGrn.productWeightModal.prodPriceValue}>{productWeightModalInput || 0}</p>
                </Col>
              </Row>
              {/* <Row>
                <Col span={24}>
                  <TextArea
                    style={data.purchaseGrn.productWeightModal.remarksInput}
                    rows={4}
                    placeholder="Remarks"
                    value={filteredData.remarks}
                    onChange={(e) => setFilteredData({ ...filteredData, remarks: e.target.value })}
                  />
                </Col>
              </Row> */}
            </Col>
            <Col span={15}>
              <Card bodyStyle={{ padding: 15 }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Input
                      ref={productWeightInputRef}
                      value={productWeightModalInput}
                      // type={currentWeightSelectedProduct.isDecimal === true ? "number" : "text"}
                      onChange={(e) => {
                        let newNumber = currentWeightSelectedProduct.isDecimal === true ? e.target.value : e.target.value.replace(/[^\d]*/g, "");
                        setProductWeightModalInput(newNumber);
                      }}
                      style={data.purchaseGrn.productWeightModal.topayInput}
                      size="large"
                      onKeyPress={(e) => (e.charCode === 13 ? addManualWeightToProduct(currentWeightSelectedProduct, productWeightModalInput) : null)}
                    />
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("1")}>
                        1
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("2")}>
                        2
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("3")}>
                        3
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("x")}>
                        <img src={Backspace} alt="back space" width={20} height={15} />
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("4")}>
                        4
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("5")}>
                        5
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("6")}>
                        6
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button
                        className="amt-dial-btn"
                        onClick={() => currentWeightSelectedProduct.isDecimal && handleWeightManual(".")}
                        disabled={currentWeightSelectedProduct.isDecimal === false}
                      >
                        .
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("7")}>
                        7
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("8")}>
                        8
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("9")}>
                        9
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("0")}>
                        0
                      </button>
                    </AspectRatio>
                  </Col>
                  <Button
                    size="large"
                    style={data.purchaseGrn.productWeightModal.confirmQtyBtn}
                    onClick={() => addManualWeightToProduct(currentWeightSelectedProduct, productWeightModalInput)}
                  >
                    <img src={Tick} alt="" />
                  </Button>
                </Row>
              </Card>
            </Col>
          </Row>
        </Modal>
        {/* PRODUCT WEIGHT MODAL END */}
      </div>
    </Spin>
  );
};

export default PurchaseGRN;
