import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Button, Modal, message } from "antd";
import { MinusOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { serverUrl, CWCoreServicesUrl } from "../../constants/config";
import Axios from "axios";
import _ from "lodash";
import { clock } from "../../utility/clock";
// import logoIcon from "../../assets/images/cwpos-blue.png";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import CircleCloseIcon from "../../assets/images/circle-close-icon.svg";
import DefaultProductImage from "../../assets/images/default-product.webp";
import db from "../../database";
import "../style.css";
import data from "../../constants/dayClose.json";

const DayClose = () => {
  const dynamicHeight = 0.5625;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const history = useHistory();

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  // Api Services starts //
  const [todaySales, setTodaySales] = useState([]);
  const [todayPurchase, setTodayPurchase] = useState([]);
  const [todayGoodsSold, setTodayGoodsSold] = useState([]);
  const [todayTransactionCount, setTodayTransactionCount] = useState([]);
  const [stockToOrder, setStockToOrder] = useState([]);
  const [openingStock, setOpeningStock] = useState([]);
  const [closingStock, setClosingStock] = useState([]);
  const [top10Products, setTop10Products] = useState([]);
  const [slow10Products, setSlow10Products] = useState([]);
  const [recurringCust, setRecurringCust] = useState([]);

  const bUnitId = { bunitid: tillData.tillAccess.csBunit.csBunitId };
  const stringifiedFields = JSON.stringify(bUnitId);
  const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
  const stringRequest = JSON.stringify(updatedStrings);

  const csBunitId = { cs_bunit_id: tillData.tillAccess.csBunit.csBunitId };
  const newStringifiedFields = JSON.stringify(csBunitId);
  const newUpdatedStrings = newStringifiedFields.replace(/\\"/g, '\\"');
  const newStringRequest = JSON.stringify(newUpdatedStrings);

  useEffect(() => {
    const todaySalesInput = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"6165cb997eff6c54181de0e4", params: ${stringRequest}) }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: todaySalesInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { executeAPIBuilder } = response.data.data;
      const newResponse = JSON.parse(executeAPIBuilder);
      setTodaySales(newResponse);
    });

    const todayPurchaseInput = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"616e7ee27eff6c54181de165", params: ${newStringRequest}) }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: todayPurchaseInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { executeAPIBuilder } = response.data.data;
      const newResponse = JSON.parse(executeAPIBuilder);
      setTodayPurchase(newResponse);
    });

    const todayGoodsSold = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"616e810c7eff6c54181de169", params: ${newStringRequest}) }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: todayGoodsSold,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { executeAPIBuilder } = response.data.data;
      const newResponse = JSON.parse(executeAPIBuilder);
      setTodayGoodsSold(newResponse);
    });

    const paramsInput = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"6165cdeb7eff6c54181de0e8", params: ${stringRequest}) }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { executeAPIBuilder } = response.data.data;
      const newResponse = JSON.parse(executeAPIBuilder);
      setTodayTransactionCount(newResponse);
    });

    const stockToOrderInput = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"616d48157eff6c54181de127", params: ${newStringRequest}) }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: stockToOrderInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { executeAPIBuilder } = response.data.data;
      const newResponse = JSON.parse(executeAPIBuilder);
      db.products
        .where("mProductId")
        .startsWithAnyOfIgnoreCase(_.map(newResponse, "product"))
        .toArray()
        .then((productsFetched) => {
          let response = newResponse.map((prodItem, prodIndex) => {
            let prodImageIndex = _.findIndex(productsFetched, (item) => item.mProductId === prodItem.product);
            return {
              ...prodItem,
              mProductId: prodItem.product,
              qty: prodItem.sales,
              name: prodImageIndex >= 0 ? productsFetched[prodImageIndex].name : "",
              img: prodImageIndex >= 0 ? productsFetched[prodImageIndex].imageurl : "",
              productCode: prodImageIndex >= 0 ? productsFetched[prodImageIndex].value : "",
            };
          });
          setStockToOrder([...response]);
        });
    });

    const openingStockInput = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"616d48ad7eff6c54181de12c", params: ${newStringRequest}) }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: openingStockInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { executeAPIBuilder } = response.data.data;
      const newResponse = JSON.parse(executeAPIBuilder);
      setOpeningStock(newResponse);
    });

    const closingStockInput = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"616d37447eff6c54181de10b", params: ${newStringRequest}) }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: closingStockInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { executeAPIBuilder } = response.data.data;
      const newResponse = JSON.parse(executeAPIBuilder);
      setClosingStock(newResponse);
    });

    const recurringCustInput = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"6165d1657eff6c54181de0ec", params: ${newStringRequest}) }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: recurringCustInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { executeAPIBuilder } = response.data.data;
      const newResponse = JSON.parse(executeAPIBuilder);
      setRecurringCust(newResponse);
    });

    const top10ProductsInput = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"616e703f7eff6c54181de159", params: ${newStringRequest}) }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: top10ProductsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { executeAPIBuilder } = response.data.data;
      const newResponse = JSON.parse(executeAPIBuilder);
      db.products
        .where("mProductId")
        .startsWithAnyOfIgnoreCase(_.map(newResponse, "m_product_id"))
        .toArray()
        .then((productsFetched) => {
          let response = newResponse.map((prodItem, prodIndex) => {
            let prodImageIndex = _.findIndex(productsFetched, (item) => item.mProductId === prodItem.m_product_id);
            return {
              ...prodItem,
              mProductId: prodItem.m_product_id,
              qty: prodItem.saleqty,
              name: prodImageIndex >= 0 ? productsFetched[prodImageIndex].name : "",
              img: prodImageIndex >= 0 ? productsFetched[prodImageIndex].imageurl : "",
            };
          });
          setTop10Products([...response]);
        });
    });

    const slow10ProductsInput = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"616e730a7eff6c54181de15e", params: ${newStringRequest}) }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: slow10ProductsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { executeAPIBuilder } = response.data.data;
      const newResponse = JSON.parse(executeAPIBuilder);
      db.products
        .where("mProductId")
        .startsWithAnyOfIgnoreCase(_.map(newResponse, "m_product_id"))
        .toArray()
        .then((productsFetched) => {
          let response = newResponse.map((prodItem, prodIndex) => {
            let prodImageIndex = _.findIndex(productsFetched, (item) => item.mProductId === prodItem.m_product_id);
            return {
              ...prodItem,
              mProductId: prodItem.m_product_id,
              qty: prodItem.saleqty,
              name: prodImageIndex >= 0 ? productsFetched[prodImageIndex].name : "",
              img: prodImageIndex >= 0 ? productsFetched[prodImageIndex].imageurl : "",
            };
          });
          setSlow10Products([...response]);
        });
    });
  }, []);

  const totalSales = _.sumBy(stockToOrder, "qty");
  // Api Services ends //

  const handlePosLogo = () => {
    history.push("/dashboard");
  }

  const [confirmModalClose, setConfirmModalClose] = useState(false);

  const handleHome = (e) => {
    if (e.keyCode === 27 && e.keyCode !== 13) {
      if (document.activeElement.className === "ant-modal-wrap") {
        setConfirmModalClose(false);
      } else {
        Modal.confirm({
          content: <div>Go to Home Screen?</div>,
          okText: "Ok",
          onOk: () => {
            history.push("/dashboard");
            setConfirmModalClose(false);
          },
          onCancel: () => {
            setConfirmModalClose(false);
          },
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleHome);
    return () => {
      window.removeEventListener("keydown", handleHome);
    };
  }, []);

  const handleSubmit = () => {
    const paramsInput = {
      query: `mutation {
        createStockIndent(
          product: {
          userId: "${tillData.tillAccess.csUserId}"
          csBunitId:"${tillData.tillAccess.csBunit.csBunitId}" 
          products:[
            ${stockToOrder.map(
              (item) => `{ mProductId: "${item.mProductId}", qty: ${item.qty} }`
            )}
          ]
        }){
          status
          message
        }
      }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      if (response.data.data.createStockIndent.status === "200") {
        message.success("Stock Order Submitted Successfully");
      }
    });
  };

  // Qty Increment Decrement in table starts //

  const onFieldButton = (fieldIndex, action) => {
    let newStockToOrder = [...stockToOrder];
    if (action === "plus") {
      newStockToOrder[fieldIndex].qty += 1;
    } else if (action === "minus" && newStockToOrder[fieldIndex].qty > 0) {
      newStockToOrder[fieldIndex].qty -= 1;
    } else if (action !== "plus" && action !== "minus") {
      newStockToOrder[fieldIndex].qty = Number(action);
    }
    setStockToOrder([...newStockToOrder]);
  };

  // Qty Increment Decrement in table ends //

  // Add Product To Stock Modal Starts //

  const [addProdToStockDisplayModal, setAddProdToStockDisplayModal] = useState(false);
  const [addProdsToStockList, setAddProdsToStockList] = useState([]);
  const [addProdToStock, setAddProdToStock] = useState("");
  const [searchAddProdToStock, setSearchAddProdToStock] = useState(addProdsToStockList);

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const handleAddProdToStock = () => {
    db.products
      .where("mProductId")
      .startsWithAnyOfIgnoreCase("")
      .toArray()
      .then((productsFetched) => {
        let remainingProductsList = _.differenceBy(productsFetched, stockToOrder, "mProductId");
        setAddProdsToStockList([...remainingProductsList]);
        setSearchAddProdToStock([...remainingProductsList]);
        setAddProdToStockDisplayModal(true);
      });
  };

  const addProdToStockSearch = (value) => {
    if (value !== "") {
      const results = addProdsToStockList.filter((prodToStock) => {
        return prodToStock.name.toLowerCase().startsWith(value.toLowerCase());
      });
      setSearchAddProdToStock(results);
    } else {
      setSearchAddProdToStock(addProdsToStockList);
    }
    setAddProdToStock(value);
  };

  const addProdToStockFromList = (record) => {
    let newRecord = { ...record };
    newRecord.product = newRecord.name;
    newRecord.productCode = newRecord.value;
    newRecord.qty = 1;
    newRecord.stock = 0;
    newRecord.img = newRecord.imageurl;
    setStockToOrder([...stockToOrder, newRecord]);
    setAddProdToStockDisplayModal(false);
  };

  // Add Product To Stock Modal Ends //

  const recurringCustomervalue = (recurringCust[0]?.rec_customer / todayTransactionCount[0]?.trx_count) * 100;

  return (
    <div>
      <div style={data.dayClose.header}>
        <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
          <Col span={2}>
            <img src={MenuIcon} style={data.dayClose.header.menuIcon} alt="" />
          </Col>
          <Col span={10}>
            <label style={data.dayClose.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
          </Col>
          <Col span={8}>
            <label style={data.dayClose.header.tillName}>{tillData.tillAccess.cwrTill.till}</label>
          </Col>
          <Col span={2} style={data.dayClose.header.right}>
            <label style={data.dayClose.header.time}>{displayClock}</label>
          </Col>
          <Col span={2} style={data.dayClose.header.right}>
            <img onClick={handlePosLogo} src={POSLogo} style={data.dayClose.header.logoIcon} alt="" />
          </Col>
        </Row>
      </div>
      <div>
        <Row style={{ padding: data.dayClose.padding }}>
          <Col span={14}>
            <Row>
              <Col span={8} style={{ padding: "0px 7px 7px 0px" }}>
                <Card bodyStyle={{ padding: 10 }} style={data.dayClose.salesCard}>
                  <p style={data.dayClose.todaysTitle}>Today's Sales</p>
                  <p style={data.dayClose.todaysValue}>₹ {(todaySales[0]?.sales || 0).toFixed(2)}</p>
                </Card>
              </Col>
              <Col span={8} style={{ padding: "0px 7px 7px 0px" }}>
                <Card bodyStyle={{ padding: 10 }} style={data.dayClose.totalGoodsSale}>
                  <p style={data.dayClose.todaysTitle}>Cost of Goods Sold</p>
                  <p style={data.dayClose.todaysValue}>₹ {(todayGoodsSold[0]?.cogs || 0).toFixed(2)}</p>
                </Card>
              </Col>
              <Col span={8} style={{ padding: "0px 7px 7px 0px" }}>
                <Card bodyStyle={{ padding: 10 }} style={data.dayClose.grossProfitCard}>
                  <p style={data.dayClose.todaysTitle}>Today's Gross Profit</p>
                  <p style={data.dayClose.todaysValue}>₹ {(todaySales[0]?.sales - todayGoodsSold[0]?.cogs || 0).toFixed(2)}</p>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ padding: "0px 7px 7px 0px" }}>
                <Card bodyStyle={{ padding: 10 }} style={data.dayClose.openingStockCard}>
                  <p style={data.dayClose.todaysTitle}>Opening Stock Value</p>
                  <p style={data.dayClose.todaysValue}>₹ {(openingStock[0]?.opening_stock || 0).toFixed(2)}</p>
                </Card>
              </Col>
              <Col span={8} style={{ padding: "0px 7px 7px 0px" }}>
                <Card bodyStyle={{ padding: 10 }} style={data.dayClose.purchaseCard}>
                  <p style={data.dayClose.todaysTitle}>Today's Purchase</p>
                  <p style={data.dayClose.todaysValue}>₹ {(todayPurchase[0]?.purchase_value || 0).toFixed(2)}</p>
                </Card>
              </Col>
              <Col span={8} style={{ padding: "0px 7px 7px 0px" }}>
                <Card bodyStyle={{ padding: 10 }} style={data.dayClose.closingStockCard}>
                  <p style={data.dayClose.todaysTitle}>Closing Stock Value</p>
                  <p style={data.dayClose.todaysValue}>₹ {(closingStock[0]?.closing_stock || 0).toFixed(2)}</p>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ padding: "0px 7px 7px 0px" }}>
                <Card bodyStyle={{ padding: 10 }} style={data.dayClose.totalTransactionsCard}>
                  <p style={data.dayClose.todaysTitle}>Total Transactions</p>
                  <p style={data.dayClose.todaysValue}>{todayTransactionCount[0]?.trx_count || 0}</p>
                </Card>
              </Col>
              <Col span={8} style={{ padding: "0px 7px 7px 0px" }}>
                <Card bodyStyle={{ padding: 10 }} style={data.dayClose.avgTransactionsCard}>
                  <p style={data.dayClose.todaysTitle}>Average Transaction Value</p>
                  <p style={data.dayClose.todaysValue}>₹ {(todaySales[0]?.sales / todayTransactionCount[0]?.trx_count || 0).toFixed(2)}</p>
                </Card>
              </Col>
              <Col span={8} style={{ padding: "0px 7px 7px 0px" }}>
                <Card bodyStyle={{ padding: 10 }} style={data.dayClose.recurringCustCard}>
                  <p style={data.dayClose.todaysTitle}>Recurring Customers (%)</p>
                  <p style={data.dayClose.todaysValue}>{(recurringCustomervalue || 0).toFixed(2)}</p>
                </Card>
              </Col>
            </Row>
            <Row style={{ paddingTop: "4%" }}>
              <Col span={12} style={{ padding: "0px 7px 0px 0px" }}>
                <Card bodyStyle={{ padding: 10 }} style={data.dayClose.productMovingCards}>
                  <p style={data.dayClose.movingProductsTitle}>Top 10 Products Sales</p>
                  <Scrollbars autoHide className="topSlowProdsScroll">
                    {top10Products.map((item, index) => (
                      <Row>
                        <Col span={4}>
                          <div style={{ marginBottom: "2px" }}>
                            <img onError={setDefaultImage} src={item.img} alt="" style={data.dayClose.topSlowProdImg} />
                          </div>
                        </Col>
                        <Col span={14} style={{ alignSelf: "center" }}>
                          <p style={data.dayClose.movingProductsDetails}>{item.name}</p>
                        </Col>
                        <Col
                          span={6}
                          style={{
                            paddingRight: 5,
                            textAlign: "right",
                            alignSelf: "center",
                          }}
                        >
                          <p style={data.dayClose.movingProductsDetails}>{item.qty}</p>
                        </Col>
                      </Row>
                    ))}
                  </Scrollbars>
                </Card>
              </Col>
              <Col span={12} style={{ padding: "0px 7px 0px 0px" }}>
                <Card bodyStyle={{ padding: 10 }} style={data.dayClose.productMovingCards}>
                  <p style={data.dayClose.movingProductsTitle}>Slow Moving Products</p>
                  <Scrollbars autoHide className="topSlowProdsScroll">
                    {slow10Products.map((item, index) => (
                      <Row>
                        <Col span={4}>
                          <div style={{ marginBottom: "2px" }}>
                            <img onError={setDefaultImage} src={item.img} alt="" style={data.dayClose.topSlowProdImg} />
                          </div>
                        </Col>
                        <Col span={14} style={{ alignSelf: "center" }}>
                          <p style={data.dayClose.movingProductsDetails}>{item.name}</p>
                        </Col>
                        <Col
                          span={6}
                          style={{
                            paddingRight: 5,
                            textAlign: "right",
                            alignSelf: "center",
                          }}
                        >
                          <p style={data.dayClose.movingProductsDetails}>{item.qty}</p>
                        </Col>
                      </Row>
                    ))}
                  </Scrollbars>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Card bodyStyle={{ padding: 0 }} style={data.dayClose.stockOrderCard} className="stockOrderCard">
              <Row style={{ padding: 10 }}>
                <Col span={12}>
                  <p style={data.dayClose.stockOrderText}>Stock To Order</p>
                </Col>
                <Col span={12} style={{ paddingTop: 4, textAlign: "right" }}>
                  <p style={data.dayClose.stockOrderAdd} onClick={() => handleAddProdToStock()}>
                    + ADD
                  </p>
                </Col>
              </Row>
              <Row style={data.dayClose.tableHeadingRow}>
                <Col span={4} />
                <Col span={10}>
                  <p style={data.dayClose.tableHeading}>Product</p>
                </Col>
                <Col span={4}>
                  <p style={data.dayClose.tableHeading}>Stock</p>
                </Col>
                <Col span={6} style={{ textAlign: "center" }}>
                  <p style={data.dayClose.tableHeading}>Quantity</p>
                </Col>
              </Row>
              <div style={{ padding: 10 }}>
                <Scrollbars autoHide className="stockOrdersScroll">
                  {stockToOrder.map((item, index) => (
                    <Row style={{ marginBottom: 5 }}>
                      <Col span={4}>
                        <img onError={setDefaultImage} src={item.img} alt="" style={data.dayClose.prodImgInTable} />
                      </Col>
                      <Col span={10} style={{ paddingTop: 5 }}>
                        <Row>
                          <p style={data.dayClose.desc}>{item.productCode}</p>
                        </Row>
                        <Row>
                          <p style={data.dayClose.prodTitle}>{item.name}</p>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <p style={data.dayClose.prodStock}>{item.stock}</p>
                      </Col>
                      <Col span={6} style={{ textAlign: "center", alignSelf: "center" }}>
                        <div style={data.dayClose.qtyInputsDiv}>
                          <MinusOutlined style={data.dayClose.decrementInpt} type="minus" onClick={() => onFieldButton(index, "minus")} />
                          <Form.Item style={data.dayClose.qtyInputFormitem}>
                            <Input
                              value={item.qty}
                              style={data.dayClose.qtyInput}
                              onChange={(e) => {
                                onFieldButton(index, e.target.value);
                              }}
                            />
                          </Form.Item>
                          <PlusOutlined style={data.dayClose.incrementInpt} type="plus" onClick={() => onFieldButton(index, "plus")} />
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Scrollbars>
                <div style={data.dayClose.prodCountSumitBtnDiv}>
                  <Row style={{ paddingTop: "10%" }}>
                    <Col span={12}>
                      <p style={data.dayClose.prodStockCount}>Products Count {stockToOrder.length}</p>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <p style={data.dayClose.prodStockQty}>Products Qty {totalSales}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Button size="large" style={data.dayClose.submitBtn} onClick={handleSubmit}>
                        <span style={data.dayClose.submitBtn.label}>SUBMIT</span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Add Product Modal Starts */}
      <Modal
        visible={addProdToStockDisplayModal}
        onCancel={() => setAddProdToStockDisplayModal(false)}
        closable={false}
        footer={null}
        // width="40%"
        bodyStyle={{ padding: 15 }}
      >
        <Row>
          <Col span={12}>
            <p style={data.dayClose.addProdToStockModal.modalTitle}>Add Product</p>
          </Col>
          <Col span={12}>
            <img src={CircleCloseIcon} alt="" style={{ float: "right" }} onClick={() => setAddProdToStockDisplayModal(false)} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              size="large"
              placeholder="Search"
              value={addProdToStock}
              onChange={(e) => {
                addProdToStockSearch(e.target.value);
              }}
              // allowClear
              suffix={<SearchOutlined />}
              className="stockProdSearchIpt"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Scrollbars autoHide className="addProdStockOrdersScroll">
            {searchAddProdToStock && searchAddProdToStock.length > 0
              ? searchAddProdToStock.map((item, index) => (
                  <Row style={{ marginBottom: 5 }}>
                    <Col span={4}>
                      <img onError={setDefaultImage} src={item.imageurl} alt="" style={data.dayClose.prodImgInTable} />
                    </Col>
                    <Col span={14} style={{ paddingTop: 5 }}>
                      <Row>
                        <p style={data.dayClose.desc}>Description</p>
                      </Row>
                      <Row>
                        <p style={data.dayClose.prodTitle}>{item.name}</p>
                      </Row>
                    </Col>
                    <Col span={6} style={{ textAlign: "center", alignSelf: "center" }}>
                      <Button style={data.dayClose.addBtn} onClick={() => addProdToStockFromList(item)}>
                        <span style={data.dayClose.addBtn.label}>ADD +</span>
                      </Button>
                      {/* <div style={data.dayClose.qtyInputsDiv}>
                        <MinusOutlined
                          style={data.dayClose.decrementInpt}
                          type="minus"
                          onClick={() => onFieldButton("minus")}
                        />
                        <Form.Item style={data.dayClose.qtyInputFormitem}>
                          <Input
                            value={1}
                            style={data.dayClose.qtyInput}
                            // onChange={onFieldInput}
                            // onBlur={onFieldOut}
                          />
                        </Form.Item>
                        <PlusOutlined
                          style={data.dayClose.incrementInpt}
                          type="plus"
                          onClick={() => onFieldButton("plus")}
                        />
                      </div> */}
                    </Col>
                  </Row>
                ))
              : ""}
          </Scrollbars>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <Button
              size="large"
              style={data.dayClose.submitBtn}
              // onClick={openPaymentModal}
            >
              <span style={data.dayClose.submitBtn.label}>ADD</span>
            </Button>
          </Col>
        </Row>
      </Modal>
      {/* Add Product Modal Ends */}
    </div>
  );
};

export default DayClose;
