import React, { useState, useEffect } from "react";
import { Row, Col, Card, Input, Button, Modal, message, Select, Empty, Spin, notification } from "antd";
import { LoadingOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../constants/config";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";
import _ from "lodash";
import { clock } from "../../utility/clock";
// import logoIcon from "../../assets/images/cwpos-blue.png";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import DefaultProductImage from "../../assets/images/default-product.webp";
import CircleCloseIcon from "../../assets/images/circle-close-icon.svg";
import Backspace from "../../assets/images/backspac.png";
import Tick from "../../assets/images/tick-small.svg";
import "../style.css";
import db from "../../database";
import data from "../../constants/purchaseGrn.json";
import BarCodeComponent from "../../lib/BarCodeComponet";
import AspectRatio from "react-aspect-ratio";

const { Option } = Select;
const { TextArea } = Input;

const Wastage = () => {
  const dynamicHeight = 0.5625;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  const handlePosLogo = () => {
    history.push("/dashboard");
  }

  const [confirmModalClose, setConfirmModalClose] = useState(false);

  const handleHome = (e) => {
    if (e.keyCode === 27 && e.keyCode !== 13) {
      if (document.activeElement.className === "ant-modal-wrap") {
        setConfirmModalClose(false);
      } else {
        Modal.confirm({
          content: <div>Go to Home Screen?</div>,
          okText: "Ok",
          onOk: () => {
            history.push("/dashboard");
            setConfirmModalClose(false);
          },
          onCancel: () => {
            setConfirmModalClose(false);
          },
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleHome);
    return () => {
      window.removeEventListener("keydown", handleHome);
    };
  }, []);

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [filteredData, setFilteredData] = useState({
    wastageType: "",
    remarks: "",
  });

  const [wastageTypeProductList, setWastageTypeProductList] = useState([]);

  const onSelect = (value) => {
    setFilteredData({ ...filteredData, wastageType: value });
  };

  const [addProdToStockDisplayModal, setAddProdToStockDisplayModal] = useState(false);
  const [addProdsToStockList, setAddProdsToStockList] = useState([]);
  const [addProdToStock, setAddProdToStock] = useState("");
  const [searchAddProdToStock, setSearchAddProdToStock] = useState(addProdsToStockList);
  const [displayManualQtyWeightInput, setDisplayManualQtyWeightInput] = useState(false);
  const [productWeightModalInput, setProductWeightModalInput] = useState("");
  const [currentWeightSelectedProduct, setCurrentWeightSelectedProduct] = useState({});

  const handleAddProdToStock = () => {
    setAddProdToStock("");
    db.products
      .where("mProductId")
      .startsWithAnyOfIgnoreCase("")
      .toArray()
      .then((productsFetched) => {
        let newProductsFetched = productsFetched.map((item, index) => {
          return {
            ...item,
            qty: 0,
          };
        });
        setAddProdsToStockList([...newProductsFetched]);
        setSearchAddProdToStock([...newProductsFetched]);
        setAddProdToStockDisplayModal(true);
      });
  };

  const addProdToStockSearch = (value) => {
    if (value !== "") {
      const results = addProdsToStockList.filter((prodToStock) => {
        return prodToStock.name.toLowerCase().startsWith(value.toLowerCase());
      });
      setSearchAddProdToStock(results);
    } else {
      setSearchAddProdToStock(addProdsToStockList);
    }
    setAddProdToStock(value);
  };

  const handleWeightModal = (prod) => {
    setCurrentWeightSelectedProduct(prod);
    setAddProdToStockDisplayModal(false);
    setDisplayManualQtyWeightInput(true);
  };

  const handleWeightManual = (value) => {
    if (productWeightModalInput === "" && value === "x") {
      setProductWeightModalInput("");
    } else if (value === "x") {
      setProductWeightModalInput(`${productWeightModalInput.toString().substring(0, productWeightModalInput.toString().length - 1)}`);
    } else {
      setProductWeightModalInput(`${productWeightModalInput}${value}`);
    }
  };

  const addManualWeightToProduct = (value) => {
    const wastageProdInput = {
      query: `query{
        getWastageProduct1(bUnit:"${tillData.tillAccess.csBunit.csBunitId}", warehouseId:"${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}"
        productId:"${value.mProductId}"){
        mProductId
        qty
    }
    }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: wastageProdInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      let responseData = response.data.data.getWastageProduct1;
      if (productWeightModalInput <= responseData.qty && productWeightModalInput !== "") {
        let newWastageProductList = [...wastageTypeProductList];
        const prodIndex = _.findIndex(addProdsToStockList, ["mProductId", responseData.mProductId]);
        // if (prodIndex === -1) {
        addProdsToStockList[prodIndex].qty = Number(productWeightModalInput);
        addProdsToStockList[prodIndex]["remarks"] = filteredData.remarks;
        const wastageIndex = _.findIndex(newWastageProductList, ["mProductId", responseData.mProductId]);
        if (wastageIndex !== -1) {
          newWastageProductList.splice(wastageIndex, 1, addProdsToStockList[prodIndex]);
        } else {
          newWastageProductList.push(addProdsToStockList[prodIndex]);
        }
        setWastageTypeProductList([...newWastageProductList]);
        setDisplayManualQtyWeightInput(false);
        setProductWeightModalInput("");
        setFilteredData({
          ...filteredData,
          remarks: "",
        });
      } else {
        message.error("Input quantity is greater than on hand quntity");
      }
    });
  };

  const key = `open${Date.now()}`;
  const btn = (
    <Button className="notificationCnfmBtn" onClick={() => notification.close(key)}>
      Confirm
    </Button>
  );

  const handleReceive = () => {
    const formatYmd = (date) => date.toISOString().slice(0, 10);
    const newDate = formatYmd(new Date());
    const uniqueId = uuidv4().replace(/-/g, "").toUpperCase();
    const wastageProductsArray = [];
    wastageTypeProductList.map((item, index) => {
      item.qty > 0 &&
        wastageProductsArray.push(
          `{
          mProductId : "${item.mProductId}"
          csUomId : "${item.csUomId}"
            qty : ${item.qty}
          }`
        );
    });

    const createGRNInput = {
      query: `mutation {
        insertStockWriteOff (wastage : {
          mWastageId :"${uniqueId}"
          csBunitId : "${tillData.tillAccess.csBunit.csBunitId}"
          mWarehouseId: "${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}"
          createdby : "${tillData.tillAccess.csUserId}"
          type : "${filteredData.wastageType}"
          date : "${newDate}"
          description : ""
          lines : [${wastageProductsArray}]
        }) {
          code
          status
          message
          documentNo
          recordId
        }
      }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: createGRNInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      if (response.data.data.insertStockWriteOff.code === "200") {
        // message.success(response.data.data.insertStockWriteOff.message);
        notification.open({
          message: `Document No - ${response.data.data.insertStockWriteOff.documentNo} ${response.data.data.insertStockWriteOff.message}`,
          type: "success",
          btn,
          key,
          duration: 15,
          style: {
            width: 500,
          },
        });
        setFilteredData({
          wastageType: "",
          skuInput: "",
          inputQty: 0,
          responseProducts: [],
          selectedProduct: {},
        });
        setWastageTypeProductList([]);
      }
    });
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  return (
    <div>
      <Spin indicator={antIcon} spinning={loading} tip="...Loading">
        {/* <BarCodeComponent onScan={handleSku} /> */}
        <div style={data.purchaseGrn.header}>
          <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
            <Col span={2}>
              <img src={MenuIcon} style={data.purchaseGrn.header.menuIcon} alt="" />
            </Col>
            <Col span={10}>
              <label style={data.purchaseGrn.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
            </Col>
            <Col span={8}>
              <label style={data.purchaseGrn.header.tillName}>{tillData.tillAccess.cwrTill.till}</label>
            </Col>
            <Col span={2} style={data.purchaseGrn.header.right}>
              <label style={data.purchaseGrn.header.time}>{displayClock}</label>
            </Col>
            <Col span={2} style={data.purchaseGrn.header.right}>
              <img onClick={handlePosLogo} src={POSLogo} style={data.purchaseGrn.header.logoIcon} alt="" />
            </Col>
          </Row>
        </div>
        <div style={{ padding: 10 }}>
          <p style={data.purchaseGrn.purchaseText}>Wastage</p>
          <Row style={{ paddingBottom: 10 }}>
            <Col span={8}>
              <Row>
                <Col span={7} style={{ alignSelf: "center" }}>
                  <p style={data.purchaseGrn.inputLabel}>Wastage Type</p>
                </Col>
                <Col span={17}>
                  <Select
                    placeholder="Wastage Type"
                    onChange={(e) => onSelect(e)}
                    value={filteredData.wastageType}
                    style={data.purchaseGrn.supplierSelect}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    className="supplierSelect"
                  >
                    <Option key="1" value="DM">
                      DM - Damage
                    </Option>
                    <Option key="2" value="EX">
                      EX - Expired
                    </Option>
                    <Option key="3" value="SC">
                      SC - Scrap
                    </Option>
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col span={2} style={{ alignSelf: "center", paddingLeft: 20 }}>
              <p style={data.purchaseGrn.wastageProdAdd} onClick={() => handleAddProdToStock()}>
                + ADD
              </p>
            </Col>
            <Col span={14} />
          </Row>
          <Card key="z" style={data.purchaseGrn.mainCardFirst} bodyStyle={{ padding: 0 }}>
            <Row style={data.purchaseGrn.headingRow}>
              <Col span={8}>
                <strong>Product</strong>
              </Col>
              <Col span={8} style={{ textAlign: "center" }}>
                <strong>Wastage Qty</strong>
              </Col>
              <Col span={8} style={{ textAlign: "center" }}>
                <strong>Remarks</strong>
              </Col>
            </Row>
            {wastageTypeProductList.length > 0 ? (
              <div>
                <Scrollbars autoHide className="purchaseGrnScroll">
                  {wastageTypeProductList.map((item, index) => (
                    <Row style={{ padding: "10px 10px 0px" }}>
                      <Col span={8}>
                        <Row>
                          <Col span={4}>
                            <img onError={setDefaultImage} src={item.imageurl} alt="" style={data.purchaseGrn.prodImgInTable} />
                          </Col>
                          <Col span={20} style={{ paddingTop: 5 }}>
                            <Row>
                              <p style={data.purchaseGrn.desc}>{item.value}</p>
                            </Row>
                            <Row>
                              <p style={data.purchaseGrn.prodTitle}>{item.name}</p>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} style={{ alignSelf: "center", textAlign: "center" }}>
                        <Input
                          value={item.qty}
                          style={data.purchaseGrn.wastageQtyInput}
                          onClick={() => {
                            handleWeightModal(item);
                            setProductWeightModalInput(item.qty);
                            setFilteredData({ ...filteredData, remarks: item.remarks });
                          }}
                        />
                      </Col>
                      <Col span={8} style={{ alignSelf: "center", textAlign: "center" }}>
                        <p style={data.purchaseGrn.prodRemarks}>{item.remarks}</p>
                      </Col>
                    </Row>
                  ))}
                </Scrollbars>
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: "10% 0px" }} />
            )}
            <div style={{ display: wastageTypeProductList.length > 0 ? "block" : "none" }}>
              <Row style={{ padding: 10 }}>
                <Col span={8}>
                  <p style={data.purchaseGrn.totalProducts}>Total Products: {_.filter(wastageTypeProductList, (item) => item?.qty > 0)?.length || 0}</p>
                </Col>
                <Col span={8}>
                  <p style={data.purchaseGrn.totalWastageQty}>Total WastageQty: {_.sumBy(wastageTypeProductList, "qty")} </p>
                </Col>
                <Col span={8} />
              </Row>
              <Row style={{ padding: 10 }}>
                <Col span={21} />
                <Col span={3} style={{ float: "right" }}>
                  <Button style={data.purchaseGrn.receiveBtn} onClick={handleReceive}>
                    <span style={data.purchaseGrn.receiveBtn.label}>CONFIRM</span>
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </div>

        {/* ADD PRODUCTS MODAL START */}
        <Modal
          visible={addProdToStockDisplayModal}
          onCancel={() => setAddProdToStockDisplayModal(false)}
          closable={false}
          footer={null}
          // width="40%"
          bodyStyle={{ padding: 15 }}
        >
          <Row>
            <Col span={12}>
              <p style={data.purchaseGrn.addProdToStockModal.modalTitle}>Add Product</p>
            </Col>
            <Col span={12}>
              <img src={CircleCloseIcon} alt="" style={{ float: "right" }} onClick={() => setAddProdToStockDisplayModal(false)} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Input
                size="large"
                placeholder="Search"
                value={addProdToStock}
                onChange={(e) => {
                  addProdToStockSearch(e.target.value);
                }}
                allowClear
                suffix={<SearchOutlined />}
                className="stockProdSearchIpt"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Scrollbars autoHide className="addWastageProdScroll">
              {searchAddProdToStock && searchAddProdToStock.length > 0
                ? searchAddProdToStock.map((item, index) => (
                    <Row style={{ marginBottom: 5, cursor: "pointer" }} onClick={() => handleWeightModal(item)}>
                      <Col span={4}>
                        <img onError={setDefaultImage} src={item.imageurl} alt="" style={data.purchaseGrn.prodImgInTable} />
                      </Col>
                      <Col span={20} style={{ paddingTop: 5 }}>
                        <Row>
                          <p style={data.purchaseGrn.desc}>{item.value}</p>
                        </Row>
                        <Row>
                          <p style={data.purchaseGrn.prodTitle}>{item.name}</p>
                        </Row>
                      </Col>
                    </Row>
                  ))
                : ""}
            </Scrollbars>
          </Row>
        </Modal>
        {/* ADD PRODUCTS MODAL END */}

        {/* PRODUCT WEIGHT MODAL START */}
        <Modal
          visible={displayManualQtyWeightInput}
          closable={false}
          // onCancel={() => {
          //   setDisplayManualQtyWeightInput(false);
          //   setProductWeightModalInput("");
          //   setFilteredData({ ...filteredData, remarks: "" });
          // }}
          footer={null}
          width="45%"
          bodyStyle={{ padding: 10 }}
        >
          <Row>
            <Col span={12}>
              <p style={data.purchaseGrn.productWeightModal.modalTitle}>Enter the product quantity</p>
            </Col>
            <Col span={12}>
              <p style={data.purchaseGrn.productWeightModal.closeIconP}>
                <CloseOutlined
                  onClick={() => {
                    setDisplayManualQtyWeightInput(false);
                    setProductWeightModalInput("");
                    setFilteredData({ ...filteredData, remarks: "" });
                  }}
                  style={data.purchaseGrn.productWeightModal.closeIcon}
                />
              </p>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col span={9}>
              <Card bodyStyle={{ padding: 10 }} style={data.purchaseGrn.productWeightModal.imgCard}>
                <img onError={setDefaultImage} width="100%" height="100%" src={currentWeightSelectedProduct.imageurl} alt="" />
              </Card>
              <Row style={{ paddingTop: 10 }}>
                <h3 style={data.purchaseGrn.productWeightModal.prodHead}>{currentWeightSelectedProduct.name || ""}</h3>
              </Row>
              <Row>
                <Col span={9}>
                  <h3 style={data.purchaseGrn.productWeightModal.prodHead}>MRP</h3>
                  <h3 style={data.purchaseGrn.productWeightModal.prodHead}>Quantity</h3>
                </Col>
                <Col span={15}>
                  <p style={data.purchaseGrn.productWeightModal.prodPriceValue}>Rs. {(currentWeightSelectedProduct.slistprice || 0).toFixed(2)}</p>
                  <p style={data.purchaseGrn.productWeightModal.prodPriceValue}>{productWeightModalInput || 0}</p>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <TextArea
                    style={data.purchaseGrn.productWeightModal.remarksInput}
                    rows={4}
                    placeholder="Remarks"
                    value={filteredData.remarks}
                    onChange={(e) => setFilteredData({ ...filteredData, remarks: e.target.value })}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={15}>
              <Card bodyStyle={{ padding: 15 }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Input
                      value={productWeightModalInput}
                      // type={currentWeightSelectedProduct.isDecimal === true ? "number" : "text"}
                      onChange={(e) => {
                        console.log('key', e.target.value);
                        let newNumber = currentWeightSelectedProduct.isDecimal === true ? e.target.value : e.target.value.replace(/[^\d]*/g, "");
                        setProductWeightModalInput(newNumber);
                      }}
                      style={data.purchaseGrn.productWeightModal.topayInput}
                      size="large"
                    />
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("1")}>
                        1
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("2")}>
                        2
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("3")}>
                        3
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("x")}>
                        <img src={Backspace} alt="back space" width={20} height={15} />
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("4")}>
                        4
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("5")}>
                        5
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("6")}>
                        6
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button
                        className="amt-dial-btn"
                        onClick={() => currentWeightSelectedProduct.isDecimal && handleWeightManual(".")}
                        disabled={currentWeightSelectedProduct.isDecimal === false}
                      >
                        .
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("7")}>
                        7
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("8")}>
                        8
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("9")}>
                        9
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("0")}>
                        0
                      </button>
                    </AspectRatio>
                  </Col>
                  <Button size="large" style={data.purchaseGrn.productWeightModal.confirmQtyBtn} onClick={() => addManualWeightToProduct(currentWeightSelectedProduct)}>
                    <img src={Tick} alt="" />
                  </Button>
                </Row>
              </Card>
            </Col>
          </Row>
        </Modal>
        {/* PRODUCT WEIGHT MODAL END */}
      </Spin>
    </div>
  );
};

export default Wastage;
