import React, { Fragment } from "react";
import { Row, Col, Card, Button, Radio, Modal, Checkbox, Badge } from "antd";
import { CloseOutlined, SyncOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import "react-aspect-ratio/aspect-ratio.css";
import "../../style.css";
import "../../globalStyles.css";
import data from "../../../constants/restaurantPos.json";
import { htmlPrint } from "../../../lib/printer/htmlPrinter";

import DecrementImg from "../../../assets/images/Decrement2.svg";
import IncrementImg from "../../../assets/images/Increment2.svg";
import TimerIcon from "../../../assets/images/timer-icon.svg";
import MobileIcon from "../../../assets/images/mobileIcon.svg";
import POSIcon from "../../../assets/images/pointofsale.svg";
import _ from "lodash";

const PointOfSaleRestaurantModals = (props) => {
  const {
    displayAddOnSelection,
    handleAddOnModalClose,
    addOnsList,
    selectedProduct,
    selectedAddons,
    handleAddOnValue,
    currencyType,
    selectedProductInCart,
    handleQty,
    handleAdd,
    selectedItemsModalOpen,
    setSelectedItemsModalOpen,
    selectProductInCart,
    handleDisplayOk,
    displayOrderItemsModal,
    orderStatus,
    selectedOrderStatus,
    handleOrderStatusSelection,
    handleOrderStatus,
    setDisplayOrderItemsModal,
    orderDetails,
    handleStatusButton,
    barcodeOrderStatusModal,
    setBarcodeOrderStatusModal,
    barcodeOrderList,
    handleBarcodeOrderPrint,
    handleAccept,
    cart,
    tillData,
    openPaymentModal,
    billConfirmationModalOpen,
    setBillConfirmationModalOpen,
  } = props;

  const handleBillPrint = () => {
    htmlPrint(cart, "Receipt");
    setBillConfirmationModalOpen(false);
  };

  return (
    <Fragment>
      {/* Add On Popup Starts */}
      <Modal
        closable={false}
        footer={null}
        bodyStyle={{ padding: 13, background: "#ECECEC", marginTop: "30px" }}
        visible={displayAddOnSelection}
        onCancel={handleAddOnModalClose}
        width={
          (addOnsList?.requiredList?.length > 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 0) ||
          (addOnsList?.requiredList?.length === 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1)
            ? "35%"
            : addOnsList?.requiredList?.length > 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1
            ? "48%"
            : "60%"
        }
      >
        <Row>
          <Col span={24}>
            <p style={data.restaurantPos.content.addOnSelectionModal.title}>{selectedProduct?.name}</p>
          </Col>
        </Row>
        <Scrollbars autoHide className="addOnsModalScroll">
          <Row>
            {addOnsList?.requiredList?.length > 0 ? (
              <Col
                span={addOnsList?.optionsList?.length === 0 ? 24 : Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1 ? 12 : 8}
                style={{ paddingRight: 10, paddingBottom: 10 }}
              >
                <Card bodyStyle={{ padding: 5 }} style={data.restaurantPos.content.addOnSelectionModal.mainCard}>
                  <Row>
                    <Col span={24}>
                      <p style={data.restaurantPos.content.addOnSelectionModal.cardTitle}>{addOnsList?.requiredList[0]?.mAddonGroup?.name || ""}</p>
                    </Col>
                  </Row>
                  <Scrollbars autoHide className="addOnSelectionScroll">
                    <Radio.Group
                      value={_.intersection(addOnsList?.requiredList || [], selectedAddons)?.[0] || {}}
                      onChange={(e) => handleAddOnValue(e, "addOnRadio")}
                      style={{ width: "100%" }}
                    >
                      {addOnsList?.requiredList?.map((item) => (
                        <Card style={data.restaurantPos.content.addOnSelectionModal.addOnCard} bodyStyle={{ padding: 10 }}>
                          <Row>
                            <Col span={24}>
                              <Radio value={item}>
                                {" "}
                                <span style={data.restaurantPos.content.addOnSelectionModal.addOnTitle}>{item.name}</span>
                              </Radio>
                            </Col>
                          </Row>
                        </Card>
                      ))}
                    </Radio.Group>
                  </Scrollbars>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {addOnsList?.optionsList?.length > 0
              ? Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name")).map(([key, value]) => (
                  <Col
                    span={
                      addOnsList?.requiredList?.length === 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1
                        ? 24
                        : (addOnsList?.requiredList?.length > 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1) ||
                          Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 2
                        ? 12
                        : 8
                    }
                    style={{ paddingRight: 10, paddingBottom: 10 }}
                  >
                    <Card bodyStyle={{ padding: 5 }} style={data.restaurantPos.content.addOnSelectionModal.mainCard}>
                      <Row>
                        <Col span={24}>
                          <p style={data.restaurantPos.content.addOnSelectionModal.cardTitle}>{key || ""}</p>
                        </Col>
                      </Row>
                      <Scrollbars autoHide className="addOnSelectionScroll">
                        {value?.map((item, index) => (
                          <Card style={data.restaurantPos.content.addOnSelectionModal.addOnCard} bodyStyle={{ padding: 10 }}>
                            <Row>
                              <Col span={20}>
                                <Checkbox
                                  checked={selectedAddons.findIndex((itemAddOn) => itemAddOn.mProductAddOnId === item.mProductAddOnId) !== -1}
                                  value={item}
                                  onChange={(e) => handleAddOnValue(e, "addOnCheck")}
                                >
                                  {" "}
                                  <span style={data.restaurantPos.content.addOnSelectionModal.addOnTitle}>{item.name}</span>
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <span style={data.restaurantPos.content.addOnSelectionModal.addOnPrice}>
                                  {currencyType.currSymbolLeft}
                                  {item.price}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        ))}
                      </Scrollbars>
                    </Card>
                  </Col>
                ))
              : ""}
          </Row>
        </Scrollbars>
        <Row style={{ paddingTop: 15 }}>
          <Col span={12}>
            <Row style={{ paddingTop: 10 }}>
              <Col span={8} style={{ paddingRight: 5 }}>
                <p style={data.restaurantPos.content.addOnSelectionModal.cardTitle}>Quantity</p>
              </Col>
              <Col span={2}>
                <img src={DecrementImg} alt="Decrement" onClick={() => handleQty("minus")} width={20} />
              </Col>
              <Col span={2}>
                <p style={data.restaurantPos.content.addOnSelectionModal.qtyValue}>{selectedProduct?.weight || 1}</p>
              </Col>
              <Col span={3}>
                <img src={IncrementImg} alt="Increment" onClick={() => handleQty("plus")} width={20} />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <Button size="large" style={data.restaurantPos.content.orderCart.checkoutBtn} className="addButton" onClick={handleAdd}>
                  <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>ADD</span>
                </Button>
              </Col>
              <Col span={12}>
                <Button size="large" style={data.restaurantPos.content.addOnSelectionModal.cancelBtn} onClick={handleAddOnModalClose} className="cancelButton">
                  <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>CANCEL</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      {/* Add On Popup Ends */}

      {/* Display Order Taking Products Starts */}
      <Modal
        visible={selectedItemsModalOpen}
        closable={false}
        footer={null}
        // width="65%"
        bodyStyle={{ padding: 10 }}
        onCancel={() => setSelectedItemsModalOpen(false)}
      >
        <Row>
          <Col span={12}>
            <p style={data.restaurantPos.content.selectedItemsDisplayModal.title}>Order Items</p>
          </Col>
          <Col span={12}>
            <p style={data.restaurantPos.content.selectedItemsDisplayModal.closeIconP}>
              <CloseOutlined onClick={() => setSelectedItemsModalOpen(false)} style={data.restaurantPos.content.selectedItemsDisplayModal.closeIcon} />
            </p>
          </Col>
        </Row>
        <Card bodyStyle={{ padding: 10 }} style={data.restaurantPos.content.selectedItemsDisplayModal.selectedItemsCard}>
          {console.log("cart cart", cart.items)}
          <Scrollbars autoHide className="selectedItemsScroll">
            {Object.entries(_.groupBy(cart?.items || [], "productCategoryName")).map(([key, value]) => (
              <div style={data.restaurantPos.content.orderCart.thirdRow}>
                <Row>
                  <p style={data.restaurantPos.content.orderCart.productCategory}>{key}</p>
                </Row>
                {value?.map((item, index) => (
                  <div
                    style={{
                      backgroundColor: _.intersectionWith([item], [selectedProductInCart], _.isEqual)?.length > 0 ? "#f1f1f1" : "",
                      padding: "5px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <Row
                      onClick={() => {
                        selectProductInCart(item);
                      }}
                    >
                      <Col span={14}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>{item.name}</p>
                      </Col>
                      <Col span={6}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>
                          <Row>
                            <Col span={10}>
                              {currencyType.currSymbolLeft} {Number(_.sumBy(item?.productAddons, "price") || 0) + item.realPrice}
                            </Col>
                            <Col span={5}>X</Col>
                            <Col span={9}>
                              <span style={data.restaurantPos.content.orderCart.productQty}>
                                <span style={data.restaurantPos.content.orderCart.productDetails}>{item.weight}</span>
                              </span>
                            </Col>
                          </Row>
                        </p>
                      </Col>
                      <Col span={4} style={data.restaurantPos.content.orderCart.amtTextAlign}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>
                          {currencyType.currSymbolLeft}
                          {item.nettotal}
                        </p>
                      </Col>
                    </Row>
                    {item?.productAddons?.map((subitem) => (
                      <div style={data.restaurantPos.content.orderCart.thirdRow}>
                        <Row>
                          <Col span={10} style={data.restaurantPos.content.orderCart.modifierCol}>
                            <p style={data.restaurantPos.content.orderCart.modifier}>{subitem.name}</p>
                          </Col>
                          <Col
                            span={14}
                            // style={data.restaurantPos.content.orderCart.amtTextAlign}
                          >
                            <p style={data.restaurantPos.content.orderCart.modifierPrice}>+ {subitem.price}</p>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </Scrollbars>
        </Card>
        <Row style={data.restaurantPos.content.selectedItemsDisplayModal.okCancelBtns}>
          <Button
            size="large"
            className="customerDetailsCancelBtn"
            style={data.restaurantPos.content.selectedItemsDisplayModal.selectedItemsCancelBtn}
            onClick={() => setSelectedItemsModalOpen(false)}
          >
            CANCEL
          </Button>
          <Button
            size="large"
            className="customerDetailsSubmitBtnBtn"
            style={data.restaurantPos.content.selectedItemsDisplayModal.selectedItemsSubmitBtn}
            htmlType="submit"
            onClick={handleDisplayOk}
          >
            OK
          </Button>
        </Row>
      </Modal>
      {/* Display Order Taking Products Starts */}

      {/* Kitchen Display Modal Starts */}
      <Modal
        closable={false}
        footer={null}
        bodyStyle={{ padding: 0, background: "#ECECEC" }}
        visible={displayOrderItemsModal}
        // onCancel={handleAddOnModalClose}
        style={{ top: 10 }}
        width="90%"
      >
        <Row>
          <Col span={2}>
            <Row>
              {orderStatus?.map((statusItem, index) => (
                <Col
                  span={24}
                  style={
                    statusItem?.title === selectedOrderStatus.title
                      ? data.restaurantPos.content.orderStatusModal.selectedOrderStatusOptionsCol
                      : data.restaurantPos.content.orderStatusModal.orderStatusOptionsCol
                  }
                  onClick={() => handleOrderStatusSelection(statusItem)}
                >
                  <img src={statusItem?.imgSrc} alt={statusItem?.imgSrc} style={{ height: "46px" }} />
                  <h6 style={data.restaurantPos.content.orderStatusModal.statusOptionName}>{statusItem?.title}</h6>
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={22} style={{ padding: 10 }}>
            <Row>
              <Col span={12}>
                <p style={data.restaurantPos.content.orderStatusModal.custTitle}>{tillData.tillAccess.csBunit.name}</p>
              </Col>
              <Col span={11}>
                <p style={data.restaurantPos.content.selectedItemsDisplayModal.closeIconP}>
                  <SyncOutlined onClick={() => handleOrderStatus()} style={data.restaurantPos.content.selectedItemsDisplayModal.closeIcon} />
                </p>
              </Col>
              <Col span={1}>
                <p style={data.restaurantPos.content.selectedItemsDisplayModal.closeIconP}>
                  <CloseOutlined
                    onClick={() => {
                      setDisplayOrderItemsModal(false);
                      // localStorage.setItem('orderDetails', "[]");
                    }}
                    style={data.restaurantPos.content.selectedItemsDisplayModal.closeIcon}
                  />
                </p>
              </Col>
            </Row>
            <Row>
              <Scrollbars autoHide className="orderCardsScroll">
                <Row>
                  {_.filter(selectedOrderStatus?.title === "Parked" ? [] : orderDetails, (selecteditem) => selecteditem.status === selectedOrderStatus.statusValue)?.map(
                    (item, index) => (
                      <Col span={6} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <Card bodyStyle={{ padding: 10 }} style={data.restaurantPos.content.orderStatusModal.orderItemsCard}>
                          <Row style={{ marginBottom: 10 }}>
                            <Col span={15} style={{ paddingLeft: 5 }}>
                              <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.customerName || "Anonymous"}</p>
                              <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>#{item.orderNo}</p>
                              <div style={data.restaurantPos.content.orderStatusModal.timerDiv}>
                                <img src={TimerIcon} alt="Timer Icon" style={{ paddingRight: 5, width: 15 }} />
                                <span style={data.restaurantPos.content.orderStatusModal.orderTime}>00.02</span>
                              </div>
                            </Col>
                            <Col span={6} style={{ textAlign: "center" }}>
                              <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.dateCreated}</p>
                              <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.noOfItems} Items</p>
                            </Col>
                            <Col span={selectedOrderStatus.title === "All" ? 3 : 2} style={{ textAlign: "center" }}>
                              {item.posOrders === "N" ? (
                                <img src={MobileIcon} alt="Mobile Icon" style={data.restaurantPos.content.orderStatusModal.mobileIcon} />
                              ) : (
                                <img src={POSIcon} alt="POS Icon" style={data.restaurantPos.content.orderStatusModal.mobileIcon} />
                              )}
                            </Col>
                            {selectedOrderStatus.title === "All" ? (
                              <Col span={0} />
                            ) : (
                              <Col span={1} style={data.restaurantPos.content.orderStatusModal.statusCol}>
                                <Badge
                                  color={
                                    item.status === "NE"
                                      ? "#EF8354"
                                      : item.status === "UP"
                                      ? "#D5BD1C"
                                      : item.status === "PR"
                                      ? "#21932A"
                                      : item.status === "DE"
                                      ? "#3ADFC9"
                                      : "#ffffff"
                                  }
                                />
                              </Col>
                            )}
                          </Row>
                          <Scrollbars autoHide className="orderItemsScroll">
                            {/* {Object.entries(
                  _.groupBy(cart?.items || [], "productCategoryName")
                ).map(([key, value]) => ( */}
                            <div style={data.restaurantPos.content.orderCart.thirdRow}>
                              {item?.lineItems?.map((productItem, index) => (
                                <div
                                  style={{
                                    padding: "5px 0px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Row>
                                    <Col span={1}>
                                      <p style={data.restaurantPos.content.orderCart.productDetails}>{index + 1}</p>
                                    </Col>
                                    <Col span={14}>
                                      <p style={data.restaurantPos.content.orderCart.productDetails}>{productItem.name}</p>
                                    </Col>
                                    <Col span={9}>
                                      <p style={data.restaurantPos.content.orderCart.productDetails}>
                                        <Row>
                                          <Col span={10}>
                                            {currencyType.currSymbolLeft}{" "}
                                            {Number(_.sumBy(productItem?.addOnProducts, "price") || 0) + (item.posOrders === "Y" ? productItem.salePrice : productItem.price)}
                                          </Col>
                                          <Col span={5}>X</Col>
                                          <Col span={9}>
                                            <span style={data.restaurantPos.content.orderCart.productQty}>
                                              <span style={data.restaurantPos.content.orderCart.productDetails}>
                                                {item.posOrders === "Y" ? productItem.weight : productItem.quantity}
                                              </span>
                                            </span>
                                          </Col>
                                        </Row>
                                      </p>
                                    </Col>
                                  </Row>
                                  {productItem?.addOnProducts?.map((subitem) => (
                                    <div style={data.restaurantPos.content.orderCart.thirdRow}>
                                      <Row>
                                        <Col span={10} style={data.restaurantPos.content.orderStatusModal.orderModifierCol}>
                                          <p style={data.restaurantPos.content.orderCart.modifier}>{subitem.name}</p>
                                        </Col>
                                        <Col
                                          span={14}
                                          // style={data.restaurantPos.content.orderCart.amtTextAlign}
                                        >
                                          <p style={data.restaurantPos.content.orderCart.modifierPrice}>+ {subitem.price}</p>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                            {/* ))} */}
                          </Scrollbars>
                          <Row>
                            <Col span={16} />
                            <Col
                              span={8}
                              style={{
                                display: selectedOrderStatus.title === "All" || selectedOrderStatus.title === "Completed" ? "none" : "block",
                              }}
                            >
                              <Button
                                size="medium"
                                style={
                                  item.status === "PR" || item.status === "DE"
                                    ? data.restaurantPos.content.orderStatusModal.statusBtnCompleted
                                    : data.restaurantPos.content.orderStatusModal.statusBtn
                                }
                                className="addButton"
                                onClick={() => handleStatusButton(item)}
                              >
                                <span style={data.restaurantPos.content.orderStatusModal.statusBtn.label}>
                                  {item.status === "NE" ? "Accept" : item.status === "UP" ? "Prepared" : item.status === "PR" ? "Complete" : "Accept"}
                                </span>
                              </Button>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    )
                  )}
                </Row>
              </Scrollbars>
            </Row>
          </Col>
        </Row>
      </Modal>
      {/* Kitchen Display Modal Ends */}

      {/* Barcode Order Popup Starts */}
      <Modal
        visible={barcodeOrderStatusModal}
        closable={false}
        footer={null}
        // width="65%"
        bodyStyle={{ padding: 10 }}
        onCancel={() => setBarcodeOrderStatusModal(false)}
      >
        <Row>
          <Col span={12}>
            <p style={data.restaurantPos.content.selectedItemsDisplayModal.title}>Order Details</p>
          </Col>
          <Col span={12}>
            <p style={data.restaurantPos.content.selectedItemsDisplayModal.closeIconP}>
              <CloseOutlined onClick={() => setBarcodeOrderStatusModal(false)} style={data.restaurantPos.content.selectedItemsDisplayModal.closeIcon} />
            </p>
          </Col>
        </Row>
        {barcodeOrderList?.map((item, index) => (
          <Card bodyStyle={{ padding: 10 }} style={data.restaurantPos.content.orderStatusModal.orderItemsCard}>
            <Row style={{ marginBottom: 10 }}>
              <Col span={15} style={{ paddingLeft: 5 }}>
                <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.customerName || "Anonymous"}</p>
                <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>#{item.orderNo}</p>
                <div style={data.restaurantPos.content.orderStatusModal.timerDiv}>
                  <img src={TimerIcon} alt="Timer Icon" style={{ paddingRight: 5, width: 15 }} />
                  <span style={data.restaurantPos.content.orderStatusModal.orderTime}>00.02</span>
                </div>
              </Col>
              <Col span={6} style={{ textAlign: "center" }}>
                <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.dateCreated}</p>
                <p style={data.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.noOfItems} Items</p>
              </Col>
              <Col span={2} style={{ textAlign: "center" }}>
                {/* <AspectRatio ratio={1}> */}
                <img src={MobileIcon} alt="Mobile Icon" style={data.restaurantPos.content.orderStatusModal.mobileIcon} />
                {/* </AspectRatio> */}
              </Col>
              <Col span={1} style={data.restaurantPos.content.orderStatusModal.statusCol}>
                <Badge color={"#EF8354"} />
              </Col>
            </Row>
            <Scrollbars autoHide className="barcodeSelectedItemsScroll">
              <div style={data.restaurantPos.content.orderCart.thirdRow}>
                {item?.lineItems?.map((productItem, index) => (
                  <div
                    style={{
                      padding: "5px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <Row>
                      <Col span={1}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>{index + 1}</p>
                      </Col>
                      <Col span={14}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>{productItem.name}</p>
                      </Col>
                      <Col span={9}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>
                          <Row>
                            <Col span={10}>
                              {currencyType.currSymbolLeft}{" "}
                              {Number(_.sumBy(productItem?.addOnProducts, "price") || 0) + (item.posOrders === "Y" ? productItem.salePrice : productItem.price)}
                            </Col>
                            <Col span={5}>X</Col>
                            <Col span={9}>
                              <span style={data.restaurantPos.content.orderCart.productQty}>
                                <span style={data.restaurantPos.content.orderCart.productDetails}>{item.posOrders === "Y" ? productItem.weight : productItem.quantity}</span>
                              </span>
                            </Col>
                          </Row>
                        </p>
                      </Col>
                    </Row>
                    {productItem?.addOnProducts?.map((subitem) => (
                      <div style={data.restaurantPos.content.orderCart.thirdRow}>
                        <Row>
                          <Col span={10} style={data.restaurantPos.content.orderStatusModal.orderModifierCol}>
                            <p style={data.restaurantPos.content.orderCart.modifier}>{subitem.name}</p>
                          </Col>
                          <Col
                            span={14}
                            // style={data.restaurantPos.content.orderCart.amtTextAlign}
                          >
                            <p style={data.restaurantPos.content.orderCart.modifierPrice}>+ {subitem.price}</p>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </Scrollbars>
            <Row>
              <Col span={12}>
                <Button size="medium" style={data.restaurantPos.content.barcodeOrderStatusModal.printBtn} className="addButton" onClick={() => handleBarcodeOrderPrint(item)}>
                  <span style={data.restaurantPos.content.barcodeOrderStatusModal.printBtn.label}>Print</span>
                </Button>
              </Col>
              <Col span={12}>
                <Button size="medium" style={data.restaurantPos.content.barcodeOrderStatusModal.acceptBtn} className="addButton" onClick={() => handleAccept(item)}>
                  <span style={data.restaurantPos.content.barcodeOrderStatusModal.acceptBtn.label}>Complete</span>
                </Button>
              </Col>
            </Row>
          </Card>
        ))}
      </Modal>
      {/* Barcode Order Popup Starts */}

      {/* MERGE TABLES POPUP STARTS */}
      <Modal visible={billConfirmationModalOpen} closable={false} footer={null} bodyStyle={{ padding: 20 }} width="30%" style={{ top: 200 }}>
        <div>
          <Row>
            <Col span={24}>
              <p style={data.restaurantPos.content.billConfirmationModal.title}>Confirm Bill</p>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col span={12}>
              <Button
                size="medium"
                className="customerDetailsSubmitBtnBtn"
                style={data.restaurantPos.content.billConfirmationModal.billTypeBtn}
                htmlType="submit"
                onClick={handleBillPrint}
              >
                Prepare Bill
              </Button>
            </Col>
            <Col span={12}>
              <Button
                size="medium"
                className="customerDetailsCancelBtn"
                style={data.restaurantPos.content.billConfirmationModal.billTypeBtn}
                onClick={() => {
                  setBillConfirmationModalOpen(false);
                  openPaymentModal();
                }}
              >
                Pay Bill
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      {/* MERGE TABLES POPUP ENDS */}
    </Fragment>
  );
};

export default PointOfSaleRestaurantModals;
