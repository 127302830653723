import React, { useState } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { AuthContext } from "../lib/auth";
import PrivateRoute from "./PrivateRoute";
import Login from "./Login";
import TillReg from "./TillRegistration";
import PointOfSale from "./PointOfSale";
import Dashboard from "./Dashboard";
import Sync from "./Sync";
import OpenTill from "./OpenTill";
import CloseTill from "./CloseTill";
import CloseTillAll from "./CloseTill/CloseTIllAll";
import TableManagement from "./PointOfSale/Restaurant/Tables-Management/tables";
import BillManagement from "./PointOfSale/Restaurant/billManagement";
import DayClose from "./DayClose";
import PurchaseGRN from "./PurchaseGrn";
import STReceipt from "./STReceipt";
import WastageQty from "./wastageQty";
import B2BSales from "./B2BSales";
import PriceUpdate from "./PriceUpdate";
import "./style.css";

const App = (props) => {
  const [authTokens, setAuthTokens] = useState(localStorage.getItem("tokens"));
  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  };

  return (
    <div className="aspectRatio">
      <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
        <Router>
          <Route exact path="/" component={Login} />
          <Route exact path="/registration" component={TillReg} />
          <PrivateRoute exact path="/pos" component={PointOfSale} />
          <PrivateRoute exact path="/sync" component={Sync} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/open-till" component={OpenTill} />
          <PrivateRoute exact path="/close-till" component={CloseTill} />
          <PrivateRoute exact path="/close-till-all" component={CloseTillAll} />
          {/* <PrivateRoute exact path="/cash-event" component={CashEvent} /> */}
          <PrivateRoute exact path="/table-management" component={TableManagement} />
          <PrivateRoute exact path="/bill-management" component={BillManagement} />
          <PrivateRoute exact path="/day-close" component={DayClose} />
          <PrivateRoute exact path="/purchase-grn" component={PurchaseGRN} />
          <PrivateRoute exact path="/stock-receipt" component={STReceipt} />
          <PrivateRoute exact path="/wastage-qty" component={WastageQty} />
          <PrivateRoute exact path="/b2b-sales" component={B2BSales} />
          <PrivateRoute exact path="/price-update" component={PriceUpdate} />
        </Router>
      </AuthContext.Provider>
    </div>
  );
};

export default App;
